var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"common_detail",attrs:{"id":"common_detail"}},[_c('div',{staticClass:"title",attrs:{"title":_vm.taskMsg.title + '(' + _vm.taskTime + ')'}},[_vm._v(" "+_vm._s(_vm.taskMsg.title)+" ("+_vm._s(_vm.taskTime)+") ")]),_c('div',{staticClass:"baseInfo"},[_c('ul',[_c('li',[_c('p',[_c('span',[_vm._v("行政村")]),_c('span',[_vm._v(_vm._s(_vm.baseInfo.XZC))])]),_c('p',[_c('span',[_vm._v("民居编号")]),_c('span',[_vm._v(_vm._s(_vm.baseInfo.MJBH))])])]),_c('li',[_c('p',[_c('span',[_vm._v("村民小组")]),_c('span',[_vm._v(_vm._s(_vm.baseInfo.XZZ))])]),_c('p',[_c('span',[_vm._v("户主姓名")]),_c('span',[_vm._v(_vm._s(_vm.baseInfo.HZXM))])])])])]),_c('div',{staticClass:"recordList scrollbar"},[(_vm.taskRwzt === 1)?_c('el-form',{ref:"ruleForm",staticClass:"demo-ruleForm daily-patrol-form"},[_c('el-row',{attrs:{"gutter":24}},_vm._l((_vm.formList),function(item,i){return _c('el-col',{key:i,class:_vm.setShow(_vm.formList, item),attrs:{"span":24}},[_c('el-form-item',{attrs:{"label":item.label,"prop":item.value}},[(
                                item.audioValue &&
                                _vm.infoData[item.audioValue] &&
                                _vm.infoData[item.audioValue].length > 0
                            )?_c('audios',{attrs:{"audioList":_vm.infoData[item.audioValue],"srcName":"CCLJ"}}):(
                                item.picValue && _vm.infoData[item.value]
                            )?_c('el-image',{directives:[{name:"viewer",rawName:"v-viewer"}],style:({ width: '100px' }),attrs:{"fit":"cover","src":_vm.infoData[item.value]}},[_c('div',{staticClass:"image-slot",attrs:{"slot":"error"},slot:"error"},[_vm._v(" 暂无数据 ")])]):(
                                item.picVideo && _vm.infoData[item.value]
                            )?_c('picVedio',{attrs:{"picCheck":false,"picVideoList":_vm.infoData[item.value],"srcName":'CCLJ'}}):_c('p',[_vm._v(" "+_vm._s(item.value ? _vm.infoData[item.value] : "")+" ")])],1)],1)}),1)],1):_vm._e(),_c('div',{staticClass:"assessList"},[(_vm.taskRwzt === 1)?_c('div',{staticClass:"all-num"},[_c('el-progress',{attrs:{"text-inside":true,"stroke-width":18,"format":_vm.format,"percentage":_vm.allFsNum,"color":_vm.customColor}}),_c('span',{style:({ color: _vm.customColor(_vm.allFsNum) })},[_vm._v(_vm._s(_vm.customText(_vm.allFsNum)))])],1):_vm._e(),_vm._l((_vm.assessList),function(item,index){return _c('div',{key:index},[_c('p',{staticClass:"a-title"},[_vm._v(" "+_vm._s(item.Mc)+" "),(_vm.taskRwzt === 1)?_c('span',[_vm._v("("+_vm._s(item.Fs)+"/"+_vm._s(item.Fz)+" 分)")]):_vm._e()]),_vm._l((item.Children),function(child,i){return _c('div',{key:'child' + i,staticClass:"child"},[_c('p',[_c('span',[_vm._v(_vm._s(child.Mc))]),(_vm.taskRwzt === 1)?_c('span',[_vm._v(_vm._s(child.Fs)+"/"+_vm._s(child.Fz)+" 分")]):_vm._e()])])})],2)})],2)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }