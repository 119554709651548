var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"residential-construct-detail",attrs:{"id":"common_detail"}},[_c('div',{staticClass:"title",attrs:{"title":_vm.taskMsg.title + '(' + _vm.taskTime + ')'}},[_vm._v(" "+_vm._s(_vm.taskMsg.title)+" ("+_vm._s(_vm.taskTime)+") ")]),_c('div',{staticClass:"recordList scrollbar"},[(_vm.taskRwzt === 1)?_c('el-form',{ref:"ruleForm",staticClass:"demo-ruleForm daily-patrol-form"},[_c('img',{staticClass:"sh-pic",attrs:{"src":require(("@image/monitoring_cloud/sh_" + (Number(
                        _vm.monitorData.SHZT
                    )) + ".png")),"alt":""}}),_c('div',{staticClass:"title-part"},[_vm._v("监测数据")]),_c('div',{staticClass:"flex flex-wrap"},_vm._l((_vm.monitorList),function(item,i){return _c('div',{key:i,staticStyle:{"width":"50%"}},[_c('el-form-item',{attrs:{"label":item.label,"prop":item.value}},[_vm._v(" "+_vm._s(item.value ? _vm.monitorData[item.value] : "")+" ")])],1)}),0),_c('div',{staticClass:"title-part",staticStyle:{"margin-top":"20px"}},[_vm._v("基础数据")]),_c('div',{staticClass:"flex flex-wrap"},_vm._l((_vm.basicList),function(item,i){return _c('div',{key:i,staticStyle:{"width":"50%"}},[(item.value == 'WQYS')?_c('el-form-item',{staticStyle:{"width":"100%","height":"30px"},attrs:{"prop":"","label":"外墙颜色"}},[_c('div',{staticStyle:{"width":"30px","height":"30px","position":"relative","left":"80px","top":"6px","border-radius":"4px"},style:({
                                background: _vm.basicData[item.value],
                            })})]):_c('el-form-item',{attrs:{"label":item.label,"prop":item.value}},[_vm._v(" "+_vm._s(item.value ? _vm.basicData[item.value] : "")+" ")])],1)}),0),_c('el-form-item',{attrs:{"label":"照片","label-width":"45px"}},[_c('picVedio',{attrs:{"picCheck":false,"picVideoList":_vm.monitorData.PIC,"srcName":"CCLJ"}})],1)],1):_vm._e(),(
                _vm.taskRwzt === 1 &&
                +_vm.monitorData.SHZT !== 2 &&
                +_vm.monitorData.SHZT !== 3
            )?_c('sn-button',{staticClass:"shButton",attrs:{"snButton":_vm.snButtonSH},on:{"update:snButton":function($event){_vm.snButtonSH=$event},"update:sn-button":function($event){_vm.snButtonSH=$event},"handleChange":_vm.handleChange}}):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }