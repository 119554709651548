const commonData = {
    loading: false,
    snTable: {
        table: {
            data: [],
            rowKey: 'id',
            ref: "taskTable",
            // treeProps: {
            //     children: 'children',
            //     hasChildren: 'hasChildren'
            // },
        },
        columns: {
            // 选择、序号
            columnsOperate: [
                //     {
                //     isShow: true,
                //     type: 'selection',
                //     selectMethod: 'showAllotBtn',
                //     width: 30
                // },
                {
                    isShow: true,
                    label: '序号',
                    type: 'index',
                    width: 50
                }
            ],
            // 数据列
            columnsData: [{
                prop: 'jcpzfamc',
                label: '任务名称',
                minWidth: 105
            }, {
                prop: 'jcrwlx',
                label: '监测任务类型',
            }, {
                prop: 'sjcjfzrmc',
                label: '采集人',
            }],
            // 操作列
            columnsBtn: {
                width: '70px',
                buttonData: [{
                    isShow: true,
                    // showMethod: '',
                    btnType: 'img',
                    operateType: 'columnInfo',
                    title: '查看详情',
                    src: 'monitoring_cloud/detail.png',
                    showMethod: 'showColumnInfoBtn',
                }, {
                    isShow: false,
                    // showMethod: '',
                    btnType: 'icon',
                    icon: 'fa fa-list-alt',
                    operateType: 'columnStatistic',
                    title: '采集数据统计',
                    color: '#21a1ff'
                }]
            }
        },
    },
    snPage: {
        tiaoshu: 20,
        count: 0,
        currentPage: 1,
        operateType: 'page'
    },
    // snCount: {
    //     eachtotal: [10, 20, 50, 100],
    //     defaultTiaoshu: 10
    // },
    snSearch: {
        inputData: [{
            // label: '关键字',
            placeholder: "关键字",
            value: 'keyword',
            operateType: 'search',
            isShow: true
        }],
        selectData: [{
                // label: '监测任务类型',
                clearable: true,
                placeholder: "监测任务类型",
                class: 'selectRound',
                list: [],
                optionLabel: 'label',
                optionValue: 'value',
                value: 'rwlx',
                operateType: 'search',
                isShow: true
            },
            // {
            //     // label: '采集人',
            //     clearable: true,
            //     placeholder: "采集人",
            //     class: 'selectRound',
            //     width: '100px',
            //     list: [],
            //     optionLabel: 'REALNAME',
            //     optionValue: 'ID',
            //     value: 'cjrid',
            //     operateType: 'search',
            //     isShow: true
            // }, 
            {
                // label: '任务状态',
                clearable: true,
                placeholder: "任务状态",
                class: 'selectRound',
                width: '100px',
                list: [{
                    ID: '1',
                    NAME: '已开始'
                }, {
                    ID: '2,3',
                    NAME: '已停止'
                }],
                optionLabel: 'NAME',
                optionValue: 'ID',
                value: 'rwzt',
                operateType: 'search',
                isShow: true
            }
        ],
        treeselectData: [{
            clearable: true,
            searchable: true,
            placeholder: '采集人',
            class: 'selectRound',
            width: '160px',
            list: [],
            optionLabel: 'Name',
            optionValue: 'Id',
            value: 'cjrid',
            operateType: 'treesearch',
            isShow: true
        }],
        // dateData: [{
        //     isShow: true,
        //     label: '任务起止时间',
        //     clearable: true,
        //     placeholder: '任务起止时间',
        //     // type: 'daterange',
        //     rangeSeparator: "",
        //     startPlaceholder: "任务起止时间",
        //     endPlaceholder: ""
        // }],
        // searchBtn: {
        //     buttonData: [{
        //         btnType: 'button',
        //         operateType: 'buttonToPatrolList',
        //         name: '查看巡查数据',
        //         round: true,
        //         backColor: '#13CE67',
        //         color: '#fff'
        //     }]
        // }
    }
};
export { commonData };