<template>
    <div id="common_detail" class="common_detail">
        <div class="title" :title="title">
            {{ title }}
        </div>
        <div class="recordList scrollbar">
            <img
                v-if="infoData.SHZT != ''"
                class="sh-pic"
                :src="
                    require(`@image/monitoring_cloud/sh_${infoData.SHZT}.png`)
                "
            />
            <div class="tablePart">
                <div v-if="taskMsg.rwzt === 1">
                    <div class="tableItem flex-y-center">
                        <div class="name">上报人员：</div>
                        <div class="desc flex-1">{{ infoData.CJRMC }}</div>
                    </div>
                    <div class="tableItem flex-y-center">
                        <div class="name">上报时间：</div>
                        <div class="desc flex-1">{{ infoData.CJSJ }}</div>
                    </div>
                    <div class="tableItem flex-y-center">
                        <div class="name">描述：</div>
                        <div class="desc flex-1">
                            {{ infoData.MS }}
                        </div>
                    </div>
                </div>
                <div class="tableItem flex" v-if="infoData.PIC">
                    <div class="name" style="padding-right: 10px">照片:</div>
                    <div class="desc flex-1 scrollbar" style="height: 550px">
                        <picVedio
                            :picVideoList="infoData.PIC"
                            srcName="CCLJ"
                            fjDesc="FJSM"
                            imgSize="100px"
                            noImgSize="80px"
                            videoSize="230px"
                        ></picVedio>
                    </div>
                </div>
            </div>

            <sn-button
                v-if="
                    taskMsg.rwzt === 1 &&
                    +infoData.SHZT !== 2 &&
                    +infoData.SHZT !== 3
                "
                class="shButton"
                :snButton.sync="snButtonSH"
                @handleChange="handleChange"
            ></sn-button>
        </div>
    </div>
</template>
<script>
import { mapActions } from "vuex";
import audios from "../task_manage/audio_show";
import picVedio from "../task_manage/pic_video_show";
export default {
    name: "common_detail",
    components: {
        audios,
        picVedio,
    },

    data() {
        return {
            title: "",
            infoData: {},
            snButtonSH: {
                buttonData: [
                    {
                        btnType: "button",
                        operateType: "pass",
                        name: "审核通过",
                        round: true,
                        backColor: "#28cdd9",
                        color: "#fff",
                    },
                    {
                        btnType: "button",
                        operateType: "noPass",
                        name: "审核不通过",
                        round: true,
                        backColor: "#6f32ff",
                        color: "#fff",
                    },
                ],
            },
        };
    },
    props: {
        detailData: {
            default: function () {
                return [];
            },
        },
        taskMsg: {
            default() {
                let obj = {
                    rwzt: "",
                    title: "",
                    time: "",
                    jcpzid: "",
                    rwid: "",
                };
                return obj;
            },
        },
    },
    watch: {
        detailData: {
            handler() {
                this.infoData = {};
                this.infoData = { ...this.detailData.dt[0] };
                this.infoData.PIC = this.detailData.fjbDtos;
            },
            deep: true,
        },
        taskMsg: {
            immediate: false,
            deep: true,
            handler() {
                let time = this.taskMsg.time
                    ? this.taskMsg.time.slice(5, 10)
                    : "";
                this.title = `${this.taskMsg.title}(${time})`;
            },
        },
    },
    computed: {},
    methods: {
        ...mapActions(["updateSHZT"]),
        handleChange(type, value) {
            if (type === "pass" || type === "noPass") {
                let shzt = type === "pass" ? 2 : 3;
                this.$confirm("是否确认审核?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                })
                    .then(() => {
                        this.checkData(shzt);
                    })
                    .catch(() => {
                        this.common.showMsg("已取消审核", "info");
                    });
            }
        },
        async checkData(shzt) {
            let res = await this.updateSHZT({
                jcpzid: this.taskMsg.jcpzid,
                rwid: this.taskMsg.rwid,
                shzt: shzt,
            });
            if (res.IsSuccess) {
                this.infoData.SHZT = shzt;
                this.$emit("changeShzt", shzt);
                this.common.showMsg("审核成功", "success");
            } else {
                this.common.showMsg("审核失败", "error");
            }
        },
    },
};
</script>
<style lang="scss" scoped>
.common_detail {
    height: 100%;
    .title {
        height: 35px;
        line-height: 35px;
        background-color: #336799;
        text-align: left;
        color: #fff;
        padding-left: 10px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
    }
    .recordList {
        background-color: #fff;
        height: calc(100% - 35px);
        padding: 10px;
        position: relative;
        .sh-pic {
            position: absolute;
            top: 0;
            right: 0;
            z-index: 1;
        }
        .tablePart {
            width: 100%;
            text-align: left;
            overflow: hidden;
            position: relative;
            .tableItem {
                width: 100%;
                min-height: 40px;
                .name {
                    width: 80px;
                    text-align: right;
                    font-size: 14px;
                    color: #606266;
                    font-weight: 600;
                }
                .desc {
                }
            }
        }
        .shButton {
            margin-top: 10px;
            margin-bottom: 10px;
            display: block;
            overflow: hidden;
        }
    }
}
</style>
