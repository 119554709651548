<template>
    <div id="dialy_patrol_detail" class="dialy_patrol_detail">
        <div class="title" :title="taskMsg.title + '(' + taskTime + ')'">
            {{ taskMsg.title }} ({{ taskTime }})
        </div>
        <div
            v-if="detailData && detailData.top && detailData.top.length > 0"
            class="recordList scrollbar"
        >
            <div
                v-if="
                    detailData.top.length === 1 && detailData.top[0].Dwlx === 0
                "
            >
                <p class="qdd-title">
                    {{ detailData.top[0].ycysmc }} ({{ taskTime }}) ：{{
                        taskRwzt === 1 ? "签到点正常" : "未签到"
                    }}
                </p>
            </div>
            <div v-else>
                <el-form
                    ref="ruleForm"
                    label-width="30%"
                    class="demo-ruleForm daily-patrol-form"
                    @submit.native.prevent
                >
                    <div
                        v-for="(item, index) in detailData.top"
                        :key="index"
                        class="form-item"
                    >
                        <el-form-item :label="item.ycysmc" prop="ycysmc">
                            <p
                                v-for="(ele, i) in item.taskZBs"
                                :key="i"
                                class="items"
                            >
                                <span>{{ ele.zbxmc }}</span>
                                <i
                                    v-if="
                                        ele.zbxzt === '正常' && taskRwzt === 1
                                    "
                                    class="fa fa-check-circle"
                                ></i>
                                <i
                                    v-if="
                                        ele.zbxzt === '异常' && taskRwzt === 1
                                    "
                                    class="fa fa-info-circle"
                                ></i>
                            </p>
                        </el-form-item>
                    </div>
                    <div class="bottom">
                        <div
                            v-if="allPage > 0 && taskRwzt === 1"
                            class="detail-msg"
                        >
                            <img
                                class="sh-pic"
                                :src="
                                    require(`@image/monitoring_cloud/sh_${bottomData.SHZT}.png`)
                                "
                                alt=""
                            />
                            <el-row :gutter="24">
                                <el-col
                                    v-for="(item, i) in formList"
                                    :key="i"
                                    :span="24"
                                >
                                    <el-form-item
                                        label-width="75px"
                                        :label="item.label"
                                        :prop="item.value"
                                    >
                                        {{
                                            item.value
                                                ? bottomData[item.value]
                                                : ""
                                        }}
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-form-item
                                label-width="75px"
                                label="情况描述"
                                prop="BZ"
                            >
                                {{ bottomData.BZ }}
                            </el-form-item>
                            <el-form-item
                                label-width="75px"
                                label="照片"
                                prop=""
                            >
                                <picVedio
                                    :picVideoList="picVedioList"
                                ></picVedio>
                            </el-form-item>
                            <el-form-item
                                label-width="75px"
                                label="处理结果"
                                prop="YCSFJC_DESC"
                            >
                                {{ bottomData.YCSFJC_DESC }}
                            </el-form-item>
                            <el-form-item class="footer" label-width="0">
                                <span class="page"
                                    >{{ curranrPage }} / {{ allPage }}</span
                                >
                                <sn-button
                                    type="primary"
                                    :snButton.sync="snButton"
                                    @handleChange="handleChange"
                                ></sn-button>
                            </el-form-item>
                            <sn-button
                                v-if="
                                    taskRwzt === 1 &&
                                    +bottomData.SHZT !== 2 &&
                                    +bottomData.SHZT !== 3
                                "
                                class="shButton"
                                :snButton.sync="snButtonSH"
                                @handleChange="handleChangeSh"
                            ></sn-button>
                        </div>
                    </div>
                </el-form>
            </div>
        </div>
    </div>
</template>
<script>
import { mapActions } from "vuex";
import picVedio from "../task_manage/pic_video_show";
export default {
    name: "dialy_patrol_detail",
    components: {
        picVedio,
    },
    props: {
        detailData: {
            default: function () {
                return {};
            },
        },
        taskMsg: {
            default: function () {
                return {};
            },
        },
    },
    data() {
        return {
            taskTime: "",
            taskRwzt: "",
            snButtonSH: {
                buttonData: [
                    {
                        btnType: "button",
                        operateType: "pass",
                        name: "审核通过",
                        round: true,
                        backColor: "#28cdd9",
                        color: "#fff",
                    },
                    {
                        btnType: "button",
                        operateType: "noPass",
                        name: "审核不通过",
                        round: true,
                        backColor: "#6f32ff",
                        color: "#fff",
                    },
                ],
            },
            snButton: {
                buttonData: [
                    {
                        btnType: "button",
                        operateType: "toPrev",
                        name: "上一条",
                        round: true,
                        backColor: "#2059CC",
                        color: "#fff",
                    },
                    {
                        btnType: "button",
                        operateType: "toNext",
                        name: "下一条",
                        round: true,
                        backColor: "#2059CC",
                        color: "#fff",
                    },
                ],
            },
            curranrPage: 0,
            allPage: 0,
            bottomData: {},
            picVedioList: [],
            options: {
                video: {
                    url: "http://ysddata.geo-compass.com/fb19da9f-7b3b-4834-bd7d-2473f411c657.mp4",
                },
                screenshot: true,
            },
            formList: [
                // {
                //     label: "监测位置",
                //     value: "MC",
                // },
                {
                    label: "巡查片区",
                    value: "YCYSFL_2",
                },
                {
                    label: "遗产要素",
                    value: "YCYSMC",
                },
                {
                    label: "上报类型",
                    value: "NAME",
                },
                {
                    label: "发生位置",
                    value: "FSWZWZ",
                },
                {
                    label: "当时采取的措施",
                    value: "DSCQDCS",
                },
                {
                    label: "评估",
                    value: "PG_DESC",
                },
                {
                    label: "上报时间",
                    value: "CJSJ",
                },
                {
                    label: "上报人员",
                    value: "CJRID_DESC",
                },
            ],
            jcpzid: "",
            rwid: "",
        };
    },
    watch: {
        detailData: {
            handler() {
                let page = this.detailData.bottom.length;
                this.allPage = page;
                this.curranrPage = page === 0 ? 0 : 1;
                this.getDetailData();
            },
            deep: true,
        },
        taskMsg: {
            handler() {
                this.taskTime = this.taskMsg.time
                    ? this.taskMsg.time.slice(5, 10)
                    : "";
                this.taskRwzt = this.taskMsg.rwzt;
                this.jcpzid = this.taskMsg.jcpzid;
                this.rwid = this.taskMsg.rwid;
            },
            deep: true,
        },
        curranrPage() {
            this.getDetailData();
        },
    },
    methods: {
        ...mapActions(["updateSHZT"]),
        handleChange(type, value) {
            if (type === "toNext") {
                this.curranrPage === this.allPage
                    ? (this.curranrPage = 1)
                    : (this.curranrPage += 1);
            } else if (type === "toPrev") {
                this.curranrPage === 1
                    ? (this.curranrPage = this.allPage)
                    : (this.curranrPage -= 1);
            }
        },
        // 点击审核按钮
        handleChangeSh(type, value) {
            if (type === "pass" || type === "noPass") {
                let shzt = type === "pass" ? 2 : 3;
                this.$confirm("是否确认审核?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                    beforeClose: (action, instance, done) => {
                        if (action === "confirm") {
                            this.checkData(shzt, done);
                        } else {
                            done();
                        }
                    },
                })
                    .then(() => {})
                    .catch(() => {
                        this.$message({
                            message: "已取消审核",
                            type: "info",
                        });
                    });
            }
        },
        async checkData(shzt, done) {
            let res = await this.updateSHZT({
                jcpzid: this.jcpzid,
                rwid: this.rwid,
                shzt: shzt,
            });
            if (res.IsSuccess) {
                done();
                this.bottomData.SHZT = shzt;
                if (
                    this.detailData.bottom &&
                    this.detailData.bottom.length !== 0
                ) {
                    this.detailData.bottom.forEach((v) => {
                        v.SHZT = shzt;
                    });
                }
                this.$emit("changeShzt", shzt);
                this.$message({
                    message: "审核成功",
                    type: "success",
                });
            } else {
                this.$message({
                    message: "审核失败",
                    type: "error",
                });
            }
        },
        getDetailData() {
            this.bottomData =
                this.detailData.bottom[this.curranrPage - 1] || {};
            this.picVedioList = this.bottomData.PIC || [];
            this.allPage = this.detailData.bottom.length;
        },
    },
};
</script>
<style lang="scss" scoped>
.dialy_patrol_detail {
    height: 100%;
    .title {
        height: 35px;
        line-height: 35px;
        background-color: #336799;
        text-align: left;
        color: #fff;
        padding-left: 10px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
    }
    .recordList {
        background-color: #f5f4f9;
        height: calc(100% - 35px);
        .demo-ruleForm {
            text-align: left;
            overflow: hidden;
            .form-item {
                background-color: #fff;
                margin-bottom: 4px;
                .items {
                    display: inline-block;
                    width: 50%;
                    text-align: right;
                    padding-right: 0;
                    color: #606266;
                    span {
                        display: inline-block;
                        height: 22px;
                        line-height: 22px;
                    }
                    i {
                        margin-left: 10px;
                        font-size: 20px;
                        color: #2b62d6;
                        &.fa-info-circle {
                            color: #ff9065;
                        }
                    }
                }
            }
            .bottom {
                position: relative;
            }
            // .detail-msg {
            //     width: 200%;
            //     display: flex;
            //     .detail-item {
            //         display: inline-block;
            //     }
            .el-row {
                margin: 0 !important;
                .el-col {
                    padding: 0 !important;
                }
            }
            // }
            .sh-pic {
                float: right;
                position: absolute;
                right: 0;
                z-index: 1;
            }
            .shButton {
                margin-top: 10px;
                margin-bottom: 10px;
            }
            .footer {
                height: 50px;
                line-height: 50px;
                background-color: #fff;
                position: relative;
                .page {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translateX(-50%) translateY(-50%);
                }
                .sn_button {
                    width: 100%;
                }
            }
        }
    }
    .qdd-title {
        padding: 5px;
        line-height: 30px;
    }
}
</style>