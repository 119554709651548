<template>
    <div id="common_detail" class="ancient-tea-tree-detail">
        <div class="title" :title="taskMsg.title + '(' + taskTime + ')'">
            {{ taskMsg.title }} ({{ taskTime }})
        </div>
        <!-- 有多张现场照，可为图片、视频 -->
        <div class="recordList scrollbar">
            <!-- 任务状态为已完成时显示采集字段 -->
            <el-form
                v-if="taskRwzt === 1"
                ref="ruleForm"
                class="demo-ruleForm daily-patrol-form"
            >
                <img
                    class="sh-pic"
                    :src="
                        require(`@image/monitoring_cloud/sh_${Number(
                            infoData.SHZT
                        )}.png`)
                    "
                    alt=""
                />
                <el-row :gutter="24">
                    <el-col
                        v-for="(item, i) in formList"
                        :key="i"
                        :class="setShow(formList, item)"
                        :span="24"
                    >
                        <el-form-item :label="item.label" :prop="item.value">
                            {{ item.value ? infoData[item.value] : "" }}
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <div v-if="showXcz">
                <el-form
                    v-if="infoData.PIC"
                    ref="ruleForm1"
                    class="demo-ruleForm daily-patrol-form"
                    @submit.native.prevent
                >
                    <el-row :gutter="24">
                        <el-col :span="24">
                            <el-form-item label="照片" label-width="45px">
                                <picVedio
                                    :picCheck="false"
                                    :picVideoList="infoData.PIC"
                                    :srcName="'CCLJ'"
                                ></picVedio>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
                <p v-else class="no-data">暂无数据</p>
            </div>
            <sn-button
                v-if="
                    taskRwzt === 1 &&
                    +infoData.SHZT !== 2 &&
                    +infoData.SHZT !== 3
                "
                class="shButton"
                :snButton.sync="snButtonSH"
                @handleChange="handleChange"
            ></sn-button>
        </div>
    </div>
</template>
<script>
import { mapActions } from "vuex";
import picVedio from "../task_manage/pic_video_show";
export default {
    name: "ancient_tea_tree_detail",
    components: {
        picVedio,
    },
    props: {
        detailData: {
            default: function () {
                return [];
            },
        },
        taskMsg: {
            default: function () {
                return {};
            },
        },
    },
    data() {
        return {
            taskTime: "",
            taskRwzt: null,
            taskType: "",
            jcpzid: "",
            rwid: "",
            infoData: {},
            snButtonSH: {
                buttonData: [
                    {
                        btnType: "button",
                        operateType: "pass",
                        name: "审核通过",
                        round: true,
                        backColor: "#28cdd9",
                        color: "#fff",
                    },
                    {
                        btnType: "button",
                        operateType: "noPass",
                        name: "审核不通过",
                        round: true,
                        backColor: "#6f32ff",
                        color: "#fff",
                    },
                ],
            },
            formList: [],
            formList307: [
                {
                    label: "上报时间：",
                    value: "CJSJ",
                },
                {
                    label: "采集编号：",
                    value: "BH",
                },
                {
                    label: "资源名称：",
                    value: "SZ_ZWM",
                },
                {
                    label: "学名：",
                    value: "SZ_BM",
                },
                {
                    label: "生长地点：",
                    value: "SZDD",
                },
                {
                    label: "经度：",
                    value: "DJ",
                },
                {
                    label: "纬度：",
                    value: "BW",
                },
                {
                    label: "海拔高度(m)：",
                    value: "HB",
                },
                {
                    label: "植被：",
                    value: "ZB",
                },
                {
                    label: "遮阴度(％)：",
                    value: "ZYD",
                },
                {
                    label: "树势：",
                    value: "SS",
                },
                {
                    label: "地形位置：",
                    value: "DXWZ_DESC",
                },
                {
                    label: "分枝密度：",
                    value: "FZMD_DESC",
                },
                {
                    label: "树型：",
                    value: "SX_DESC",
                },
                {
                    label: "树姿：",
                    value: "SZ_DESC",
                },
                {
                    label: "嫩枝茸毛：",
                    value: "NZRM_DESC",
                },
                {
                    label: "寄生植物：",
                    value: "JSZW",
                },
                {
                    label: "最低分枝高(cm)：",
                    value: "ZDFZG",
                },
                {
                    label: "树高(m)：",
                    value: "SG",
                },
                {
                    label: "树幅(m)：",
                    value: "SF",
                },
                {
                    label: "最大干围(cm)：",
                    value: "ZDGW",
                },
                {
                    label: "基部干围(cm)：",
                    value: "JBGW",
                },
                {
                    label: "叶长(cm)×宽(cm)：",
                    value: "YCYK",
                },
                {
                    label: "叶片大小：",
                    value: "YPDX_DESC",
                },
                {
                    label: "叶形：",
                    value: "YX_DESC",
                },
                {
                    label: "叶色：",
                    value: "YS_DESC",
                },
                {
                    label: "叶基：",
                    value: "YJ_DESC",
                },
                {
                    label: "叶脉对数(对)：",
                    value: "YMDS",
                },
                {
                    label: "叶身：",
                    value: "YESHEN_DESC",
                },
                {
                    label: "叶尖：",
                    value: "YEJIAN_DESC",
                },
                {
                    label: "叶面：",
                    value: "YM_DESC",
                },
                {
                    label: "叶缘：",
                    value: "YY_DESC",
                },
                {
                    label: "叶质：",
                    value: "YZ_DESC",
                },
                {
                    label: "叶背主脉茸毛：",
                    value: "YBZMRM_DESC",
                },
                {
                    label: "叶齿形态：",
                    value: "YCXT_DESC",
                },
                {
                    label: "芽叶色泽：",
                    value: "YAYE_DESC",
                },
                {
                    label: "芽叶茸毛：",
                    value: "YYR_DESC",
                },
                {
                    label: "耕作情况：",
                    value: "GZQK",
                },
                {
                    label: "病虫害发生情况：",
                    value: "BCHFSQK",
                },
            ],
        };
    },
    watch: {
        detailData: {
            handler() {
                this.infoData = {};
                this.taskType = this.taskMsg.type || "";
                this.formList = this["formList" + this.taskType];

                this.infoData = { ...this.detailData.dt[0] };
                let pics = this.detailData.fjbDtos || this.detailData.PIC;
                this.infoData.FJ_SJMS = this.detailData.FJ_SJMS;
                this.infoData.PIC = pics;
            },
            deep: true,
        },
        taskMsg: {
            handler() {
                this.taskTime = this.taskMsg.time
                    ? this.taskMsg.time.slice(5, 10)
                    : "";
                this.taskType = this.taskMsg.type || "";
                this.taskRwzt = this.taskMsg.rwzt;
                this.jcpzid = this.taskMsg.jcpzid;
                this.rwid = this.taskMsg.rwid;
                this.formList = this["formList" + this.taskType];
            },
            deep: true,
        },
    },
    computed: {
        showXcz() {
            return this.taskType != "500201";
        },
    },
    methods: {
        ...mapActions(["updateSHZT"]),
        setShow(list, item) {
            if (!item.judge && list[0].judge) {
                if (this.infoData[list[0].value] === "") {
                    return "hide-form-item";
                }
                return this.infoData[list[0].value] === list[0].keyword
                    ? list[0].class1
                    : list[0].class2;
            }
            return "";
        },
        handleChange(type, value) {
            if (type === "pass" || type === "noPass") {
                let shzt = type === "pass" ? 2 : 3;
                this.$confirm("是否确认审核?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                    beforeClose: (action, instance, done) => {
                        if (action === "confirm") {
                            this.checkData(shzt, done);
                        } else {
                            done();
                        }
                    },
                })
                    .then(() => {})
                    .catch(() => {
                        this.$message({
                            message: "已取消审核",
                            type: "info",
                        });
                    });
            }
        },
        async checkData(shzt, done) {
            let res = await this.updateSHZT({
                jcpzid: this.jcpzid,
                rwid: this.rwid,
                shzt: shzt,
            });
            if (res.IsSuccess) {
                done();
                this.infoData.SHZT = shzt;
                this.$emit("changeShzt", shzt);
                this.$message({
                    message: "审核成功",
                    type: "success",
                });
            } else {
                this.$message({
                    message: "审核失败",
                    type: "error",
                });
            }
        },
    },
};
</script>
<style lang="scss" scoped>
.ancient-tea-tree-detail {
    height: 100%;
    .title {
        height: 35px;
        line-height: 35px;
        background-color: #336799;
        text-align: left;
        color: #fff;
        padding-left: 10px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
    }
    .recordList {
        background-color: #fff;
        height: calc(100% - 35px);
        padding: 10px;
        .demo-ruleForm {
            text-align: left;
            overflow: hidden;
            position: relative;
            .sh-pic {
                position: absolute;
                top: 0;
                right: 0;
                z-index: 1;
            }
            .el-form-item {
                margin-bottom: 0 !important;
            }
            .el-row {
                margin: 0 !important;
                .el-col {
                    padding: 0 !important;
                    &.hide-form-item {
                        display: none;
                    }
                }
            }
        }
        .shButton {
            margin-top: 10px;
            margin-bottom: 10px;
            display: block;
            overflow: hidden;
        }
        .no-data {
            line-height: 30px;
            height: 30px;
            text-align: center;
        }
    }
}
</style>
