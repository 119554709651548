<template>
    <div id="common_detail"
         class="common_detail">
        <div class="title"
             :title="taskMsg.title + '(' + taskTime + ')'">
            {{ taskMsg.title }} ({{ taskTime }})
        </div>
        <!-- 游客量 -->
        <div v-if="taskType === '1103'"
             class="recordListYkl scrollbar">
            <el-form ref="ruleForm"
                     class="demo-ruleForm daily-patrol-form">
                <img v-if="taskRwzt === '1'"
                     class="sh-pic"
                     :src="
                        require(`@image/monitoring_cloud/sh_${infoDataAll[0].SHZT}.png`)
                    "
                     alt="" />
                <el-row v-if="infoDataAll && infoDataAll.length !== 0"
                        :gutter="24">
                    <el-col :span="24">
                        <el-form-item label="上报人员">
                            {{ infoDataAll[0].CJRMC }}
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row v-if="infoDataAll && infoDataAll.length !== 0"
                        :gutter="24">
                    <el-col :span="24">
                        <el-form-item label="上报时间">
                            {{ infoDataAll[0].CJSJ }}
                        </el-form-item>
                    </el-col>
                </el-row>
                <div v-for="(ele, i) in infoDataAll"
                     :key="i"
                     class="form-item">
                    <el-row :gutter="24">
                        <el-col v-for="(item, i) in formList"
                                :key="i"
                                :class="setYklShow(formList, item)"
                                :span="24">
                            <el-form-item :label="item.label"
                                          :prop="item.value">
                                {{ item.value ? ele[item.value] : "" }}
                                {{ item.unit || "" }}
                            </el-form-item>
                        </el-col>
                    </el-row>
                </div>
            </el-form>
            <sn-button v-if="
                    taskRwzt === '1' &&
                    +infoDataAll[0].SHZT !== 2 &&
                    +infoDataAll[0].SHZT !== 3
                "
                       class="shButton"
                       :snButton.sync="snButtonSH"
                       @handleChange="handleChange"></sn-button>
        </div>
        <!-- 非游客量 -->
        <!-- 主要针对保护工程：
                有基准照--单独设立监测点--只有一张现场照，且为图片
                无基准照--不单独设立监测点--有多找现场照，可为图片、视频
            -- -->
        <div v-else
             class="recordList scrollbar">
            <div v-if="infoData.JZZ"
                 @click="openContrastImg()"
                 class="img-box">
                <div class="left-img">
                    <el-image fit="cover"
                              :src="infoData.JZZ">
                        <div slot="error"
                             class="image-slot">暂无数据</div>
                    </el-image>
                    <p>基准图</p>
                </div>
                <div v-if="showXcz"
                     @click="openContrastImg()"
                     class="right-img">
                    <el-image fit="cover"
                              :src="infoData.XXZ ? infoData.XXZ[0] : ''">
                        <div slot="error"
                             class="image-slot">暂无数据</div>
                    </el-image>
                    <p>
                        <span>现场照</span>
                    </p>
                </div>
            </div>
            <div v-else>
                <div v-if="showXcz">
                    <el-form v-if="infoData.PIC"
                             ref="ruleForm1"
                             class="demo-ruleForm daily-patrol-form"
                             @submit.native.prevent>
                        <el-row :gutter="24">
                            <el-col :span="24">
                                <el-form-item label="照片"
                                              label-width="45px">
                                    <picVedio :picCheck="false"
                                              :picVideoList="infoData.PIC"
                                              :srcName="'CCLJ'"></picVedio>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-form>
                    <p v-else
                       class="no-data">暂无数据</p>
                </div>
            </div>
            <!-- 任务状态为已完成时显示采集字段 -->
            <el-form v-if="taskRwzt === 1"
                     ref="ruleForm"
                     class="demo-ruleForm daily-patrol-form">
                <img class="sh-pic"
                     :src="
                        require(`@image/monitoring_cloud/sh_${infoData.SHZT}.png`)
                    "
                     alt="" />
                <el-row :gutter="24">
                    <el-col v-for="(item, i) in formList"
                            :key="i"
                            :class="setShow(formList, item)"
                            :span="24">
                        <el-form-item :label="item.label"
                                      :prop="item.value">
                            {{ item.value ? infoData[item.value] : "" }}
                            <audios v-if="
                                    item.audioValue &&
                                    infoData[item.audioValue] &&
                                    infoData[item.audioValue].length > 0
                                "
                                    :audioList="infoData[item.audioValue]"
                                    srcName="CCLJ"></audios>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <!-- 保护工程存在视频 -->
            <div v-for="(item, ind) in videlList"
                 v-show="taskType === '24'"
                 :key="ind">
                <video width="100%"
                       controls="controls">
                    <source :src="item.CCLJ"
                            type="video/mp4" />
                    <source :src="item.CCLJ"
                            type="video/ogg" />
                    <source :src="item.CCLJ"
                            type="video/webm" />
                    <object :src="item.CCLJ"
                            width="100%">
                        <embed :src="item.CCLJ"
                               width="100%" />
                    </object>
                </video>
            </div>
            <!-- 民族文化 特殊项 基准照可有可无，有照片，可图片，可视频 -->
            <div v-if="!showXcz && infoData.PIC">
                <el-form ref="ruleForm1"
                         class="demo-ruleForm daily-patrol-form"
                         @submit.native.prevent>
                    <el-row :gutter="24">
                        <el-col :span="24">
                            <el-form-item label="照片"
                                          label-width="45px">
                                <picVedio :picCheck="false"
                                          :picVideoList="infoData.PIC"
                                          :srcName="'CCLJ'"></picVedio>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
            </div>
            <sn-button v-if="
                    taskRwzt === 1 &&
                    +infoData.SHZT !== 2 &&
                    +infoData.SHZT !== 3
                "
                       class="shButton"
                       :snButton.sync="snButtonSH"
                       @handleChange="handleChange"></sn-button>
        </div>

        <!-- 基准图-现场照 放大对比 -->
        <sn-dialog :dialogData="dialogContrastImg"
                   @closeDialog="closeContrastImg"
                   class="custom-dialog">
            <div class="flex-between contrastImg-part">
                <div class="img-item">
                    <el-image v-viewer
                              :src="infoData.JZZ"
                              fit="cover"
                              class="pointer"
                              style="width: 100%; height: 100%">
                        <div slot="error"
                             style="width: 100%; height: 100%">
                            <SnNoData width="200px"
                                      typeNum="1"></SnNoData>
                        </div>
                    </el-image>
                    <div class="label">基准图</div>
                </div>
                <div class="img-item">
                    <el-image v-viewer
                              :src="infoData.XXZ ? infoData.XXZ[0] : ''"
                              fit="cover"
                              class="pointer"
                              style="width: 100%; height: 100%">
                        <div slot="error"
                             style="width: 100%; height: 100%">
                            <SnNoData width="200px"
                                      typeNum="1"></SnNoData>
                        </div>
                    </el-image>
                    <div class="label">现场照</div>
                </div>
            </div>
        </sn-dialog>

    </div>
</template>
<script>
import { mapActions } from "vuex";
import audios from "../task_manage/audio_show";
import picVedio from "../task_manage/pic_video_show";

export default {
    name: "common_detail",
    components: {
        audios,
        picVedio,
    },
    props: {
        detailData: {
            default: function () {
                return [];
            },
        },
        taskMsg: {
            default: function () {
                return {};
            },
        },
    },
    data () {
        return {
            imgList: [],
            taskTime: "",
            taskRwzt: "",
            taskType: "",
            jcpzid: "",
            rwid: "",
            infoData: {},
            infoDataAll: [],
            currentPage: 0,
            allPage: 0,
            snButtonSH: {
                buttonData: [
                    {
                        btnType: "button",
                        operateType: "pass",
                        name: "审核通过",
                        round: true,
                        backColor: "#28cdd9",
                        color: "#fff",
                    },
                    {
                        btnType: "button",
                        operateType: "noPass",
                        name: "审核不通过",
                        round: true,
                        backColor: "#6f32ff",
                        color: "#fff",
                    },
                ],
            },
            formList: [],
            formList14: [
                {
                    label: "上报人员：",
                    value: "CJRMC",
                },
                {
                    label: "上报时间：",
                    value: "CJSJ",
                },
                {
                    label: "遗产要素保存状态：",
                    value: "YCYSBCZT_DESC",
                    judge: true,
                    keyword: "保持",
                    class1: "hide-form-item",
                    class2: "",
                },
                {
                    label: "变化原因：",
                    value: "YQBHYY_DESC",
                },
                {
                    label: "影响评估：",
                    value: "PG_DESC",
                },
                {
                    label: "情况说明：",
                    value: "SM",
                },
            ],
            formList12: [
                {
                    label: "上报人员：",
                    value: "CJRMC",
                },
                {
                    label: "上报时间：",
                    value: "CJSJ",
                },
                {
                    label: "影响评估：",
                    value: "PGNAME",
                },
                {
                    label: "情况说明：",
                    value: "SM",
                    audioValue: "FJ_SJMS",
                },
            ],
            formList24: [
                {
                    label: "上报人员：",
                    value: "CJRMC",
                },
                {
                    label: "上报时间：",
                    value: "CJSJ",
                },
                {
                    label: "项目进展描述：",
                    value: "SM",
                    audioValue: "FJ_SJMS",
                },
            ],
            formList90201: [
                {
                    label: "上报人员：",
                    value: "CJRMC",
                },
                {
                    label: "上报时间：",
                    value: "CJSJ",
                },
                {
                    label: "情况说明：",
                    value: "SM",
                    audioValue: "FJ_SJMS",
                },
            ],
            formList90301: [
                {
                    label: "上报人员：",
                    value: "CJRMC",
                },
                {
                    label: "上报时间：",
                    value: "CJSJ",
                },
                {
                    label: "是否发生变化：",
                    value: "XZSFFSBH_DESC",
                    judge: true,
                    keyword: "是",
                    class1: "",
                    class2: "hide-form-item",
                },
                {
                    label: "影响评估：",
                    value: "YXPG_DESC",
                },
                {
                    label: "情况说明：",
                    value: "QKSM",
                },
            ],
            formList1402: [
                {
                    label: "上报人员：",
                    value: "CJRMC",
                },
                {
                    label: "上报时间：",
                    value: "CJSJ",
                },
                {
                    label: "情况说明：",
                    value: "SM",
                    audioValue: "FJ_SJMS",
                },
            ],
            formList1103: [
                {
                    label: "景点名称：",
                    value: "MC",
                },
                {
                    label: "游客量：",
                    value: "YKL",
                    unit: "人",
                },
            ],
            formList1109: [
                {
                    label: "上报人员：",
                    value: "CJRMC",
                },
                {
                    label: "上报时间：",
                    value: "CJSJ",
                },
                {
                    label: "描述：",
                    value: "SM",
                },
            ],
            formList1201: [
                {
                    label: "上报人员：",
                    value: "CJRMC",
                },
                {
                    label: "上报时间：",
                    value: "CJSJ",
                },
                {
                    label: "描述：",
                    value: "MS",
                    audioValue: "FJ_SJMS",
                },
                {
                    label: "异常是否解除：",
                    value: "SFJC_DESC",
                },
            ],
            formList1105: [
                {
                    label: "上报人员：",
                    value: "CJRMC",
                },
                {
                    label: "上报时间：",
                    value: "CJSJ",
                },
                {
                    label: "描述：",
                    value: "SM",
                },
            ],
            formList90303: [
                {
                    label: "上报人员：",
                    value: "CJRMC",
                },
                {
                    label: "上报时间：",
                    value: "CJSJ",
                },
                {
                    label: "是否发生变化：",
                    value: "XZSFFSBH_DESC",
                    judge: true,
                    keyword: "是",
                    class1: "",
                    class2: "hide-form-item",
                },
                {
                    label: "影响评估：",
                    value: "YXPG_DESC",
                },
                {
                    label: "情况说明：",
                    value: "QKSM",
                },
            ],
            formList500201: [
                {
                    label: "上报人员：",
                    value: "CJRMC",
                },
                {
                    label: "上报时间：",
                    value: "CJSJ",
                },
                {
                    label: "描述：",
                    value: "MS",
                },
            ],
            videlList: [],
            dialogContrastImg: {
                title: "基准图-现场照",
                class: "task-execution",
                dialog: false,
                width: "1400px",
                action: false,
            },
        };
    },
    watch: {
        detailData: {
            handler () {
                this.infoData = {};
                this.taskType = this.taskMsg.type || "";
                this.formList = this["formList" + this.taskType];
                console.log("this.taskType-----detailData: ", this.taskType);
                if (this.taskType === "1103") {
                    // 游客量
                    this.infoDataAll = this.detailData;
                    console.log(this.infoDataAll);
                } else {
                    this.infoData = { ...this.detailData.dt[0] };
                    this.infoData.XXZ = [];
                    let pics = this.detailData.fjbDtos || this.detailData.PIC;
                    pics &&
                        pics.forEach((item) => {
                            this.infoData.XXZ.push(item.CCLJ);
                        });
                    this.infoData.FJ_SJMS = this.detailData.FJ_SJMS;
                    this.videlList =
                        this.detailData && this.detailData.Fj_VIDEO;
                    this.infoData.PIC = pics;
                    this.currentPage = 1;
                    this.allPage = this.infoData.XXZ.length;
                }
            },
            deep: true,
        },
        taskMsg: {
            handler () {
                this.taskTime = this.taskMsg.time
                    ? this.taskMsg.time.slice(5, 10)
                    : "";
                this.taskType = this.taskMsg.type || "";
                this.taskRwzt = this.taskMsg.rwzt;
                this.jcpzid = this.taskMsg.jcpzid;
                this.rwid = this.taskMsg.rwid;
                this.formList = this["formList" + this.taskType];
                console.log("this.taskType------taskMsg: ", this.taskType);
            },
            deep: true,
        },
    },
    computed: {
        showXcz () {
            return this.taskType != "500201";
        },
    },
    methods: {
        ...mapActions(["updateSHZT"]),
        changeNum (now, old) {
            this.currentPage = now + 1;
        },
        setShow (list, item) {
            if (!item.judge && list[0].judge) {
                if (this.infoData[list[0].value] === "") {
                    return "hide-form-item";
                }
                return this.infoData[list[0].value] === list[0].keyword
                    ? list[0].class1
                    : list[0].class2;
            }
            return "";
        },
        setYklShow (list, item) {
            if (item.value === "YKL" && this.taskRwzt !== "1") {
                return "hide-form-item";
            }
            return "";
        },
        handleChange (type, value) {
            if (type === "pass" || type === "noPass") {
                let shzt = type === "pass" ? 2 : 3;
                this.$confirm("是否确认审核?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                    beforeClose: (action, instance, done) => {
                        if (action === "confirm") {
                            this.checkData(shzt, done);
                        } else {
                            done();
                        }
                    },
                })
                    .then(() => { })
                    .catch(() => {
                        this.$message({
                            message: "已取消审核",
                            type: "info",
                        });
                    });
            }
        },
        async checkData (shzt, done) {
            let res = await this.updateSHZT({
                jcpzid: this.jcpzid,
                rwid: this.rwid,
                shzt: shzt,
            });
            if (res.IsSuccess) {
                done();
                this.infoData.SHZT = shzt;
                this.$emit("changeShzt", shzt);
                if (this.taskType === "1103") {
                    this.infoDataAll[0].SHZT = shzt;
                }
                this.$message({
                    message: "审核成功",
                    type: "success",
                });
            } else {
                this.$message({
                    message: "审核失败",
                    type: "error",
                });
            }
        },
        // 打开对比
        openContrastImg () {
            this.dialogContrastImg.dialog = true;
        },
        // 关闭对比
        closeContrastImg () {
            this.dialogContrastImg.dialog = false;
        },
    },
};
</script>
<style lang="scss" scoped>
.common_detail {
    height: 100%;
    .title {
        height: 35px;
        line-height: 35px;
        background-color: #336799;
        text-align: left;
        color: #fff;
        padding-left: 10px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
    }
    .recordList,
    .recordListYkl {
        background-color: #fff;
        height: calc(100% - 35px);
        padding: 10px;
        .img-box {
            width: 100%;
            overflow: hidden;
            .left-img,
            .right-img {
                width: 50%;
                float: left;
                text-align: center;
                p {
                    height: 34px;
                    line-height: 34px;
                    color: #336799;
                    background-color: #f6f5fa;
                    margin-top: 10px;
                    font-weight: 600;
                    position: relative;
                    .num {
                        position: absolute;
                        right: 10px;
                    }
                }
                .el-image {
                    max-width: 100%;
                    height: 180px;
                    vertical-align: top;
                }
            }
            .left-img {
                padding-right: 5px;
            }
            .right-img {
                padding-left: 5px;
            }
        }
        .demo-ruleForm {
            text-align: left;
            overflow: hidden;
            position: relative;
            .sh-pic {
                position: absolute;
                top: 0;
                right: 0;
                z-index: 1;
            }
            .el-form-item {
                margin-bottom: 0 !important;
            }
            .el-row {
                margin: 0 !important;
                .el-col {
                    padding: 0 !important;
                    &.hide-form-item {
                        display: none;
                    }
                }
            }
        }
        .shButton {
            margin-top: 10px;
            margin-bottom: 10px;
            display: block;
            overflow: hidden;
        }
        .no-data {
            line-height: 30px;
            height: 30px;
            text-align: center;
        }
    }
    .recordListYkl {
        background-color: #f5f4f9;
        .form-item {
            background-color: #fff;
            margin-bottom: 4px;
            padding: 0 10px;
        }
    }
    .contrastImg-part {
        width: 100%;
        height: 640px;
        .img-item {
            width: 48%;
            height: 100%;
            .label {
                width: 100%;
                color: #336799;
                background-color: #e5e5e5;
                font-weight: 600;
                text-align: center;
            }
        }
    }
}
</style>
