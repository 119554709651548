<template>
    <div id="task-execution" class="task-execution">
        <div class="sn_table_group">
            <sn-search
                class="sn-search clearfix"
                :snSearch.sync="commonData.snSearch"
                @handleChange="handleChange"
            ></sn-search>
            <div class="sn_tablebody">
                <sn-load v-show="commonData.loading"></sn-load>
                <sn-table
                    v-show="!commonData.loading"
                    ref="tableBox"
                    :snTable.sync="commonData.snTable"
                    :boxHeight.sync="boxHeight"
                    :commonMethods="commonMethods"
                    @handleChange="handleChange"
                ></sn-table>
                <sn-page
                    v-show="!commonData.loading"
                    :snPage.sync="commonData.snPage"
                    @handleChange="handleChange"
                ></sn-page>
            </div>
            <dialogGather
                ref="dialogGather"
                :btnItemShow="false"
                @saveSuccess="saveSuccess"
            ></dialogGather>
            <div class="time-line">
                <img
                    width="16px"
                    class="imgState imgStateLeft"
                    src="@image/monitoring_cloud/trangle.png"
                />
                <div ref="axisDom" class="timeline scrollbar">
                    <div
                        class="taskSelect"
                        v-if="childTask && childTask.length > 0"
                    >
                        民居任务
                        <el-select
                            v-model="pzid"
                            size="mini"
                            @change="getTaskTimeList"
                        >
                            <el-option
                                v-for="(item, index) in childTask"
                                :key="index"
                                :label="item.jcpzfamc"
                                :value="item.id"
                            ></el-option>
                        </el-select>
                    </div>
                    <div
                        v-if="currentTaskType !== '' && timeData.length > 0"
                        class="timezone"
                    >
                        <div
                            v-for="(y, ind) in timeData"
                            :key="ind"
                            class="timelist"
                        >
                            <div
                                :class="{ currentYear: y.Year === currentYear }"
                                class="year"
                            >
                                <p @click="checkYear(y)">{{ y.Year }}</p>
                                <span></span>
                            </div>
                            <div v-for="(m, key) in y.DataList" :key="key">
                                <!-- 'day3': d.Rwzt === 1 && d.name === '异常' -->
                                <div
                                    v-for="(d, index) in m.List"
                                    v-show="num === key"
                                    :key="index"
                                    :class="{
                                        currentTask: d.Isnocycle
                                            ? d.id === currentTask
                                            : d.rwid === currentTask,
                                        day0: d.Rwzt === 0,
                                        day1: d.Rwzt === 1,
                                        day2: d.Rwzt === 2,
                                        day3:
                                            d.Rwzt === 1 &&
                                            d.name !== '正常' &&
                                            +d.jcdxbid !== 308 &&
                                            d.Sjzt !== 2 &&
                                            d.Sjzt !== 3,
                                        day4:
                                            d.Rwzt === 1 &&
                                            d.name !== '正常' &&
                                            +d.jcdxbid !== 308 &&
                                            d.Sjzt === 2,
                                        day5:
                                            d.Rwzt === 1 &&
                                            d.name !== '正常' &&
                                            +d.jcdxbid !== 308 &&
                                            d.Sjzt === 3,
                                        dayNoCycle: d.Isnocycle,
                                    }"
                                    class="day"
                                    @click="checkDay(d, ind, key, index)"
                                >
                                    <p class="timeShow">
                                        {{
                                            d.Rwjssj
                                                ? d.Rwjssj.slice(5, 11)
                                                : ""
                                        }}
                                        <i class="d-time" v-if="d.Isnocycle">{{
                                            d.Rwjssj.slice(11)
                                        }}</i>
                                    </p>
                                    <span></span>
                                    <div v-if="+d.jcdxbid === 9999">
                                        <!-- <p v-if="d.Rwzt === 0" class="pstyle pstyle0">未完成</p>
                                        <p v-if="d.Rwzt === 1 && d.name === '异常'" class="pstyle pstyle3">异常</p>
                                        <p v-if="d.Rwzt === 1 && d.name === '正常'" class="pstyle pstyle1">正常</p>
                                        <p v-if="d.Rwzt === 2" class="pstyle pstyle2">已过期</p> -->
                                        <p
                                            v-if="d.Rwzt === 0"
                                            class="pstyle pstyle0"
                                        >
                                            未完成
                                        </p>
                                        <p
                                            v-if="
                                                d.Rwzt === 1 &&
                                                d.name !== '正常'
                                            "
                                        >
                                            <font class="pstyle pstyle3 ztName"
                                                >异常</font
                                            >
                                            <font
                                                v-if="
                                                    d.Sjzt !== 2 && d.Sjzt !== 3
                                                "
                                                class="shFont"
                                                >未审核</font
                                            >
                                            <font
                                                v-if="d.Sjzt === 2"
                                                class="shFont"
                                                >审核通过</font
                                            >
                                            <font
                                                v-if="d.Sjzt === 3"
                                                class="shFont"
                                                >审核不通过</font
                                            >
                                        </p>
                                        <p
                                            v-if="
                                                d.Rwzt === 1 &&
                                                d.name === '正常'
                                            "
                                            class="pstyle pstyle1"
                                        >
                                            正常
                                        </p>
                                        <p
                                            v-if="d.Rwzt === 2"
                                            class="pstyle pstyle2"
                                        >
                                            已过期
                                        </p>
                                    </div>
                                    <div v-else-if="+d.jcdxbid === 308">
                                        <p
                                            v-if="d.Rwzt === 0"
                                            class="pstyle pstyle0"
                                        >
                                            未完成
                                        </p>
                                        <p
                                            v-if="d.Rwzt === 1"
                                            class="pstyle pstyle1"
                                        >
                                            已完成
                                        </p>
                                        <p
                                            v-if="d.Rwzt === 2"
                                            class="pstyle pstyle2"
                                        >
                                            已过期
                                        </p>
                                    </div>
                                    <div v-else>
                                        <!-- <p v-if="d.Rwzt === 0" class="pstyle pstyle0">未完成</p>
                                        <p v-if="d.Rwzt === 1" class="pstyle pstyle1">已完成</p>
                                        <p v-if="d.Rwzt === 2" class="pstyle pstyle2">已过期</p> -->
                                        <p
                                            v-if="d.Rwzt === 0"
                                            class="pstyle pstyle0"
                                        >
                                            未完成
                                        </p>
                                        <!-- <p v-if="d.Rwzt === 1" class="pstyle pstyle1">已完成</p> -->
                                        <p v-if="d.Rwzt === 1">
                                            <font class="pstyle pstyle1 ztName"
                                                >已完成</font
                                            >
                                            <font
                                                v-if="
                                                    d.Sjzt !== 2 && d.Sjzt !== 3
                                                "
                                                class="shFont"
                                                >未审核</font
                                            >
                                            <font
                                                v-if="d.Sjzt === 2"
                                                class="shFont"
                                                >审核通过</font
                                            >
                                            <font
                                                v-if="d.Sjzt === 3"
                                                class="shFont"
                                                >审核不通过</font
                                            >
                                        </p>
                                        <p
                                            v-if="d.Rwzt === 2"
                                            class="pstyle pstyle2"
                                        >
                                            已过期
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="info">
                <img
                    width="16px"
                    class="imgState imgStateRight"
                    src="@image/monitoring_cloud/trangle.png"
                />
                <div class="content">
                    <div
                        v-if="currentTaskType !== '' && timeData.length > 0"
                        style="height: 100%"
                    >
                        <rcxcDetail
                            v-if="currentTaskType === '9999'"
                            :taskMsg.sync="taskMsg"
                            :detailData.sync="taskDetailData"
                            @changeShzt="changeShzt"
                        ></rcxcDetail>
                        <mjkpDetail
                            v-else-if="currentTaskType === '308'"
                            :taskMsg.sync="taskMsg"
                            :detailData.sync="taskDetailData"
                            @changeShzt="changeShzt"
                        ></mjkpDetail>
                        <gcsjcDetail
                            v-else-if="currentTaskType === '307'"
                            :taskMsg.sync="taskMsg"
                            :detailData.sync="taskDetailData"
                            @changeShzt="changeShzt"
                        ></gcsjcDetail>
                        <nationalCulture
                            v-else-if="currentTaskType === '500201'"
                            :taskMsg.sync="taskMsg"
                            :detailData.sync="taskDetailData"
                            @changeShzt="changeShzt"
                        ></nationalCulture>
                        <residentialConstruct
                            v-else-if="currentTaskType === '90501'"
                            :taskMsg.sync="taskMsg"
                            :detailData.sync="taskDetailData"
                            @changeShzt="changeShzt"
                        ></residentialConstruct>
                        <commonDetail
                            v-else
                            :taskMsg.sync="taskMsg"
                            :detailData.sync="taskDetailData"
                            @changeShzt="changeShzt"
                        ></commonDetail>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { commonData } from "./sn-table-group-common";
import { commonMethods } from "../task_manage/table-common-methods";
import dialogGather from "../task_manage/dialog_gather";//所有的弹出框
import rcxcDetail from "./daily_patrol_detail";
import mjkpDetail from "./residential_assess_detail";
import gcsjcDetail from "./ancient_tea_tree_detail.vue"; // 古茶树监测
import commonDetail from "./common_detail";
import nationalCulture from "./national_culture"; //民族文化
import residentialConstruct from "./residential_construct"; //民居建设

import { mapActions } from "vuex";
import mixins from "../task_manage/task-list-mixins";
export default {
    name: "taskmanage",
    components: {
        dialogGather,
        rcxcDetail,
        mjkpDetail,
        commonDetail,
        gcsjcDetail,
        nationalCulture,
        residentialConstruct,
    },
    mixins: [mixins],
    data() {
        return {
            commonData,
            commonMethods,
            pzid: "",
            currentRow: {},
            childTask: [], // 只是民居考评
            num: 2,
            currentYear: "",
            currentTask: "",
            currentTaskType: "",
            currentTaskTitle: "",
            taskDetailData: {},
            taskMsg: {
                time: "",
                type: "",
                title: "",
                rwzt: "",
                jcpzid: "",
                rwid: "",
            },
            rwzt: "",
            timeData: [],
            boxHeight: 500,
            yearInd: 0,
            MonthInd: 0,
            DayInd: 0,
            tableDom: null,
            axisDom: null,
        };
    },
    watch: {
        currentRow(val) {
            console.log('%c 🥛 val: ', 'font-size:20px;background-color: #93C0A4;color:#fff;', val);
            if (val && val.id) {
                this.$refs.tableBox.$refs.taskTable.setCurrentRow(val);
                let value = null;
                this.childTask = [];
                if (
                    val.jcdxbid == "308" &&
                    val.children &&
                    val.children.length > 0
                ) {
                    value = val.children[0];
                    this.childTask = val.children;
                } else {
                    value = val;
                }
                this.pzid = value.id;
                this.currentTaskType = value.jcdxbid;

                this.currentTaskTitle = value.jcpzfamc;
                this.getTaskTimeList();
            } else {
                this.currentTaskType = "";
            }
            console.log(
                "%c 🍰 this.currentTaskType: ",
                "font-size:20px;background-color: #42b983;color:#fff;",
                this.currentTaskType
            );
        },
    },
    mounted() {
        // let buttonData = this.commonData.snSearch.searchBtn.buttonData;
        // buttonData[buttonData.length - 1].operateType = 'buttonToPatrolList';
        // buttonData[buttonData.length - 1].name = '查看巡查数据';
        this.autoResize();
        this.domListener();
    },
    methods: {
        ...mapActions([
            "getRwglList",
            "getCjrList",
            "getTaskTimeLine",
            "getTaskDetails",
        ]),
        handleSelfChange(type, value) {
            switch (type) {
                case "buttonToPatrolList": // 查看巡查数据  功能不要了
                    break;
                case "rowClick": // 点击某一行，获取时间线
                    this.currentRow = value[0];
                    break;
            }
        },
        domListener() {
            setTimeout(() => {
                this.tableDom = this.$refs.tableBox.$refs.taskTable.bodyWrapper;
                this.tableDom.addEventListener(
                    "scroll",
                    this.handleTableScroll
                );

                this.axisDom = this.$refs.axisDom;
                this.axisDom.addEventListener("scroll", this.handleAxisScroll);
            }, 1000);
        },
        handleTableScroll() {
            let maxTop = $(window).height() + $(window).scrollTop();
            let $currentRow = $(".current-row").offset().top;
            if ($currentRow < 135) {
                $(".imgStateLeft").css({ top: 30 });
            } else if ($currentRow > maxTop - 85) {
                $(".imgStateLeft").css({ top: maxTop - 188 });
            } else {
                let imgLeftTop = $currentRow - 103;
                $(".imgStateLeft").css({ top: imgLeftTop });
            }
        },
        handleAxisScroll() {
            let maxTop = $(window).height() + $(window).scrollTop();
            let $currentRow = $(".currentTask").offset().top;
            if ($currentRow < 115) {
                $(".imgStateRight").css({ top: -1 });
            } else if ($currentRow > maxTop - 37) {
                $(".imgStateRight").css({ top: maxTop - 152 });
            } else {
                let imgLeftTop = $currentRow - 118;
                $(".imgStateRight").css({ top: imgLeftTop });
            }
        },
        async getTaskTimeList() {
            let res = await this.getTaskTimeLine({
                jcpzid: this.pzid,
            });
            this.timeData = res;
            this.handleTableScroll();
            if (res.length === 0) return false;
            this.currentYear = res[0].Year;
            this.currentMonth = res[0].DataList[0].Month;
            this.checkDay(res[0].DataList[0].List[0]);
        },
        checkYear(value) {
            this.currentYear = value.Year;
            this.checkDay(value.DataList[0].List[0]);
        },
        checkDay(value, key = 0, ind = 0, index = 0) {
            this.currentTask = value.Isnocycle ? value.id : value.rwid;
            this.yearInd = key;
            this.MonthInd = ind;
            this.DayInd = index;
            this.getDataDetail(value);
        },
        async getDataDetail(value) {
            this.currentTaskType = value.jcdxbid;
            let res = await this.getTaskDetails({
                jcxbid: value.jcdxbid,
                jcpzid: value.id,
                rwid: value.rwid,
            });
            this.taskMsg.type = this.currentTaskType;
            this.taskMsg.title = this.currentTaskTitle;
            this.taskMsg.time = value.Rwjssj;
            this.taskMsg.rwzt = value.Rwzt;
            this.taskMsg.jcpzid = value.id;
            this.taskMsg.rwid = value.rwid;
            this.taskDetailData = res;
            this.handleAxisScroll();
        },
        autoResize() {
            this.boxHeight = $(".sn_tablebody").height() - 10;
        },
        changeShzt(v) {
            if (
                this.timeData[this.yearInd] &&
                this.timeData[this.yearInd].DataList &&
                this.timeData[this.yearInd].DataList[this.MonthInd] &&
                this.timeData[this.yearInd].DataList[this.MonthInd].List &&
                this.timeData[this.yearInd].DataList[this.MonthInd].List[
                    this.DayInd
                ]
            ) {
                this.timeData[this.yearInd].DataList[this.MonthInd].List[
                    this.DayInd
                ].Sjzt = Number(v);
            }
            // this.getTaskTimeList(); // 请求时间轴列表
        },
    },
};
</script>
<style lang="scss" scoped>
#task-execution {
    .sn_tablebody {
        width: 40%;
        bottom: 0;
    }
    .time-line {
        position: absolute;
        top: 60px;
        left: 40%;
        bottom: 0;
        width: 25%;
        .imgState {
            position: absolute;
            top: 60px;
            left: 1px;
        }
        .timeline {
            width: calc(100% - 16px);
            // overflow-y: scroll;
            margin-left: 16px;
            background-color: #fff;
            height: 100%;
            border: 1px solid #dee5ed;
            padding: 10px 0;
            .taskSelect {
                width: calc(100% - 20px);
                margin: 0 10px 10px;
                display: flex;
                align-items: center;
                .el-select {
                    flex: 1;
                    margin-left: 10px;
                }
            }
            .timezone {
                width: 2px;
                background: #bed1e0;
                margin: 0 auto;
                -webkit-animation: heightSlide 2s linear;
                border: 1px solid transparent;
                .timelist {
                    .year {
                        position: relative;
                        margin-left: -8px;
                        margin-bottom: 18px;
                        margin-top: 20px;
                        width: 16px;
                        height: 16px;
                        border-radius: 50%;
                        background-color: #fff;
                        border: 4px solid #a0a0a0;
                        &.currentYear {
                            border: 4px solid #1560dd;
                        }
                        &.currentYear p,
                        &.currentYear p:after,
                        &.currentYear span {
                            background-color: #1560dd;
                        }
                        p {
                            position: absolute;
                            margin-left: -103px;
                            margin-top: -14px;
                            background: #a0a0a0;
                            color: #ffffff;
                            border-radius: 6px;
                            padding: 0 8px;
                            font-size: 26px;
                            cursor: pointer;
                        }
                        p:after {
                            content: "";
                            width: 12px;
                            height: 12px;
                            background-color: #a0a0a0;
                            position: absolute;
                            top: 12px;
                            right: -5px;
                            transform: rotate(45deg);
                        }
                        span {
                            width: 16px;
                            height: 2px;
                            background: #a0a0a0;
                            position: absolute;
                            left: -20px;
                            top: 3px;
                        }
                    }
                    .day {
                        height: 30px;
                        line-height: 30px;
                        display: block !important;
                        position: relative;
                        margin-left: -6px;
                        margin-bottom: 30px;
                        width: 12px;
                        height: 12px;
                        border-radius: 50%;
                        border: 3px solid #fff;
                        &.currentTask .timeShow {
                            color: #1560dd;
                            font-weight: 700;
                        }
                        &.currentTask,
                        &.currentTask > span,
                        &.currentTask > div > p {
                            background-color: #1560dd;
                        }
                        .timeShow {
                            position: absolute;
                            margin-left: -50px;
                            margin-top: -10px;
                            font-size: 16px;
                            color: #45576b;
                            cursor: pointer;
                            i.d-time {
                                font-size: 14px;
                                font-weight: 400;
                                display: block;
                                margin-top: -8px;
                                margin-left: -6px;
                            }
                        }
                        span {
                            width: 30px;
                            height: 2px;
                            position: absolute;
                            right: -33px;
                            top: 2px;
                        }
                        div {
                            width: 70px;
                            height: 35px;
                            margin-left: 42px;
                            margin-top: -15px;
                            font-size: 26px;
                            p {
                                height: 35px;
                                line-height: 35px;
                                color: #fff;
                                border-radius: 6px;
                                cursor: pointer;
                                text-align: center;
                            }
                            .shFont {
                                font-size: 12px;
                                line-height: 18px;
                                height: 18px;
                                display: inline-block;
                                background: #fff;
                                border-radius: 5px;
                                margin: 1px;
                                width: calc(100% - 2px);
                            }
                            .ztName {
                                line-height: 22px;
                            }
                        }
                    }
                    .day0,
                    .day0 > span,
                    .day0 > div > p {
                        background-color: #ff8b5c;
                    }
                    .day1,
                    .day1 > span,
                    .day1 > div > p {
                        background-color: #96de00;
                    }
                    .day2,
                    .day2 > span,
                    .day2 > div > p {
                        background-color: #a0a0a0;
                    }
                    .day3,
                    .day3 > span,
                    .day3 > div > p {
                        background-color: #fa6f1b;
                    }
                    .day3 > div .shFont {
                        color: #fa6f1b;
                    }
                    .day4,
                    .day4 > span,
                    .day4 > div > p {
                        background-color: rgb(40, 205, 217);
                    }
                    .day4 > div .shFont {
                        color: rgb(40, 205, 217);
                    }
                    .day5,
                    .day5 > span,
                    .day5 > div > p {
                        background-color: rgb(111, 50, 255);
                    }
                    .day5 > div .shFont {
                        color: rgb(111, 50, 255);
                    }
                    .day3,
                    .day4,
                    .day5 {
                        margin-bottom: 36px;
                    }
                    .day3 > div > p,
                    .day4 > div > p,
                    .day5 > div > p {
                        height: 42px;
                        line-height: 18px;
                    }
                    .dayNoCycle {
                        margin-bottom: 50px;
                    }
                }
            }
            @-webkit-keyframes heightSlide {
                0% {
                    height: 0;
                }
                100% {
                    height: 300px;
                }
            }
        }
    }
    .info {
        position: absolute;
        top: 60px;
        left: 65%;
        bottom: 0;
        width: 35%;

        .imgState {
            position: absolute;
            top: 60px;
            left: 1px;
        }
        .content {
            width: calc(100% - 16px);
            margin-left: 16px;
            background-color: #f5f4f9;
            height: 100%;
            border: 1px solid #dee5ed;
        }
    }
}
</style>