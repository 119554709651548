<template>
    <div id="common_detail" class="common_detail">
        <div class="title" :title="taskMsg.title + '(' + taskTime + ')'">
            {{ taskMsg.title }} ({{ taskTime }})
        </div>
        <div class="baseInfo">
            <ul>
                <li>
                    <p>
                        <span>行政村</span>
                        <span>{{ baseInfo.XZC }}</span>
                    </p>
                    <p>
                        <span>民居编号</span>
                        <span>{{ baseInfo.MJBH }}</span>
                    </p>
                </li>
                <li>
                    <p>
                        <span>村民小组</span>
                        <span>{{ baseInfo.XZZ }}</span>
                    </p>
                    <p>
                        <span>户主姓名</span>
                        <span>{{ baseInfo.HZXM }}</span>
                    </p>
                </li>
            </ul>
        </div>
        <div class="recordList scrollbar">
            <!-- 任务状态为已完成时显示采集字段 -->
            <el-form
                v-if="taskRwzt === 1"
                ref="ruleForm"
                class="demo-ruleForm daily-patrol-form"
            >
                <!-- <img
                    class="sh-pic"
                    :src="
                        require(`@image/monitoring_cloud/sh_${infoData.SHZT}.png`)
                    "
                    alt=""
                /> -->
                <el-row :gutter="24">
                    <el-col
                        v-for="(item, i) in formList"
                        :key="i"
                        :class="setShow(formList, item)"
                        :span="24"
                    >
                        <el-form-item :label="item.label" :prop="item.value">
                            <audios
                                v-if="
                                    item.audioValue &&
                                    infoData[item.audioValue] &&
                                    infoData[item.audioValue].length > 0
                                "
                                :audioList="infoData[item.audioValue]"
                                srcName="CCLJ"
                            ></audios>
                            <el-image
                                :style="{ width: '100px' }"
                                v-else-if="
                                    item.picValue && infoData[item.value]
                                "
                                fit="cover"
                                :src="infoData[item.value]"
                                v-viewer
                            >
                                <div slot="error" class="image-slot">
                                    暂无数据
                                </div>
                            </el-image>
                            <picVedio
                                v-else-if="
                                    item.picVideo && infoData[item.value]
                                "
                                :picCheck="false"
                                :picVideoList="infoData[item.value]"
                                :srcName="'CCLJ'"
                            ></picVedio>
                            <p v-else>
                                {{ item.value ? infoData[item.value] : "" }}
                            </p>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <div class="assessList">
                <div v-if="taskRwzt === 1" class="all-num">
                    <el-progress
                        :text-inside="true"
                        :stroke-width="18"
                        :format="format"
                        :percentage="allFsNum"
                        :color="customColor"
                    ></el-progress>
                    <span :style="{ color: customColor(allFsNum) }">{{
                        customText(allFsNum)
                    }}</span>
                </div>
                <div v-for="(item, index) in assessList" :key="index">
                    <p class="a-title">
                        {{ item.Mc }}
                        <span v-if="taskRwzt === 1"
                            >({{ item.Fs }}/{{ item.Fz }} 分)</span
                        >
                    </p>
                    <div
                        class="child"
                        v-for="(child, i) in item.Children"
                        :key="'child' + i"
                    >
                        <p>
                            <span>{{ child.Mc }}</span>
                            <span v-if="taskRwzt === 1"
                                >{{ child.Fs }}/{{ child.Fz }} 分</span
                            >
                        </p>
                    </div>
                </div>
            </div>
            <!-- <sn-button
                v-if="
                    taskRwzt === 1 &&
                        +infoData.SHZT !== 2 &&
                        +infoData.SHZT !== 3
                "
                class="shButton"
                :snButton.sync="snButtonSH"
                @handleChange="handleChange"
            ></sn-button> -->
        </div>
    </div>
</template>
<script>
import { mapActions } from "vuex";
import audios from "../task_manage/audio_show";
import picVedio from "../task_manage/pic_video_show";
export default {
    name: "residential_assess_detail",
    components: {
        audios,
        picVedio,
    },
    props: {
        detailData: {
            default: function () {
                return [];
            },
        },
        taskMsg: {
            default: function () {
                return {};
            },
        },
    },
    data() {
        return {
            taskTime: "",
            taskRwzt: "",
            taskType: "",
            jcpzid: "",
            rwid: "",
            assessList: [],
            baseInfo: {},
            infoData: {},
            pfxInfos: [],
            allFsNum: 0,
            snButtonSH: {
                buttonData: [
                    {
                        btnType: "button",
                        operateType: "pass",
                        name: "审核通过",
                        round: true,
                        backColor: "#28cdd9",
                        color: "#fff",
                    },
                    {
                        btnType: "button",
                        operateType: "noPass",
                        name: "审核不通过",
                        round: true,
                        backColor: "#6f32ff",
                        color: "#fff",
                    },
                ],
            },
            formList: [],
            formList308: [
                {
                    label: "上报人员：",
                    value: "CJRMC",
                },
                {
                    label: "上报时间：",
                    value: "CJSJ",
                },
                {
                    label: "说明：",
                    value: "SM",
                },
                {
                    label: "考评人签字：",
                    value: "KPRQZ",
                    picValue: true,
                },
                {
                    label: "照片：",
                    value: "PIC",
                    picVideo: true,
                },
            ],
            videlList: [],
        };
    },
    watch: {
        detailData: {
            handler() {
                this.infoData = {};
                this.taskType = this.taskMsg.type || "";
                this.formList = this["formList" + this.taskType];
                console.log("this.taskType-----detailData: ", this.taskType);
                console.log(
                    "%c 🥟 this.detailData: ",
                    "font-size:20px;background-color: #ED9EC7;color:#fff;",
                    this.detailData
                );
                this.infoData = { ...this.detailData.dt[0] };
                let pics = this.detailData.fjbDtos || this.detailData.PIC;
                this.infoData.PIC = pics;
                this.baseInfo = this.detailData.MjInfo[0];
                this.pfxInfos = this.detailData.pfxInfos;
                this.setPfxInfo();
            },
            deep: true,
        },
        taskMsg: {
            handler() {
                this.taskTime = this.taskMsg.time
                    ? this.taskMsg.time.slice(5, 10)
                    : "";
                this.taskType = this.taskMsg.type || "";
                this.taskRwzt = this.taskMsg.rwzt;
                this.jcpzid = this.taskMsg.jcpzid;
                this.rwid = this.taskMsg.rwid;
                this.formList = this["formList" + this.taskType];
                console.log("this.taskType------taskMsg: ", this.taskType);
            },
            deep: true,
        },
    },
    computed: {
        showXcz() {
            return this.taskType != "500201";
        },
    },
    mounted() {
        this.getAssessList();
    },
    methods: {
        ...mapActions(["updateSHZT", "getMjkpQuestion"]),
        format(percentage) {
            return percentage;
        },
        customColor(percentage) {
            let res = this.checkPercentage(percentage);
            let colorArr = ["#FB5C81", "#FBB25C", "#EDD400", "#5CFBE3"];
            return colorArr[res];
        },
        customText(percentage) {
            let res = this.checkPercentage(percentage);
            let textArr = ["不合格", "合格", "良好", "优秀"];
            return textArr[res];
        },
        checkPercentage(percentage) {
            if (percentage < 60) {
                return 0;
            } else if (percentage >= 60 && percentage < 75) {
                return 1;
            } else if (percentage >= 75 && percentage < 90) {
                return 2;
            } else if (percentage >= 90) {
                return 3;
            }
        },
        setPfxInfo() {
            let assessList = this.assessList;
            this.allFsNum = 0;
            if (assessList.length == 0) {
                console.log("取数据了");
                setTimeout(() => {
                    setPfxInfo();
                }, 500);
            } else {
                assessList.forEach((item) => {
                    if (item.Children && item.Children.length > 0) {
                        item.Fs = 0;
                        item.Children.forEach((child) => {
                            let current = this.pfxInfos.find(
                                (i) => i.Pfxid == child.Id
                            );
                            child.Fs = (current && current.Df) || 0;
                            item.Fs += child.Fs;
                        });
                        this.allFsNum += item.Fs;
                    }
                });
            }
        },
        async getAssessList() {
            let res = await this.getMjkpQuestion();
            this.assessList = res;
        },
        setShow(list, item) {
            if (!item.judge && list[0].judge) {
                if (this.infoData[list[0].value] === "") {
                    return "hide-form-item";
                }
                return this.infoData[list[0].value] === list[0].keyword
                    ? list[0].class1
                    : list[0].class2;
            }
            return "";
        },
        setYklShow(list, item) {
            if (item.value === "YKL" && this.taskRwzt !== "1") {
                return "hide-form-item";
            }
            return "";
        },
        handleChange(type, value) {
            if (type === "pass" || type === "noPass") {
                let shzt = type === "pass" ? 2 : 3;
                this.$confirm("是否确认审核?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                    beforeClose: (action, instance, done) => {
                        if (action === "confirm") {
                            this.checkData(shzt, done);
                        } else {
                            done();
                        }
                    },
                })
                    .then(() => {})
                    .catch(() => {
                        this.$message({
                            message: "已取消审核",
                            type: "info",
                        });
                    });
            }
        },
        async checkData(shzt, done) {
            let res = await this.updateSHZT({
                jcpzid: this.jcpzid,
                rwid: this.rwid,
                shzt: shzt,
            });
            if (res.IsSuccess) {
                done();
                this.infoData.SHZT = shzt;
                this.$emit("changeShzt", shzt);
                this.$message({
                    message: "审核成功",
                    type: "success",
                });
            } else {
                this.$message({
                    message: "审核失败",
                    type: "error",
                });
            }
        },
    },
};
</script>
<style lang="scss" scoped>
.common_detail {
    height: 100%;
    .title {
        height: 35px;
        line-height: 35px;
        background-color: #336799;
        text-align: left;
        color: #fff;
        padding-left: 10px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
    }
    .baseInfo {
        padding: 10px;
        background-color: #fff;
        border-bottom: 2px solid #d1dbdc;
        ul {
            border-radius: 15px;
            background-color: #d2eff1;
            li {
                display: flex;
                padding: 0 15px;
                p {
                    width: 50%;
                    display: flex;
                    justify-content: space-between;
                    padding: 10px;
                    &:nth-child(2n + 1) {
                        border-right: 1px solid #fff;
                    }
                    span:nth-child(2n + 1) {
                        font-weight: 700;
                    }
                }
                &:nth-child(1) p {
                    border-bottom: 1px solid #fff;
                }
            }
        }
    }
    .recordList {
        background-color: #fff;
        height: calc(100% - 137px);
        padding: 10px;
        .assessList {
            .all-num {
                margin-top: 10px;
                display: flex;
                align-items: center;
                .el-progress {
                    flex: 1;
                }
                span {
                    margin-left: 10px;
                    font-weight: 700;
                }
            }
            p.a-title {
                border-bottom: 1px solid #336799;
                height: 30px;
                line-height: 30px;
                margin-top: 10px;
                font-size: 16px;
                font-weight: 600;
                color: #336799;
            }
            .child {
                line-height: 20px;
                > p {
                    margin-left: 1em;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    border-bottom: 1px solid #ebeef1;
                    padding: 10px 0;
                    span {
                        &:nth-child(2n + 1) {
                            flex: 1;
                        }
                        &:nth-child(2n) {
                            width: 50px;
                            margin-left: 10px;
                            text-align: center;
                        }
                    }
                }
            }
        }
        .img-box {
            width: 100%;
            overflow: hidden;
            .left-img,
            .right-img {
                width: 50%;
                float: left;
                text-align: center;
                p {
                    height: 34px;
                    line-height: 34px;
                    color: #336799;
                    background-color: #f6f5fa;
                    margin-top: 10px;
                    font-weight: 600;
                    position: relative;
                    .num {
                        position: absolute;
                        right: 10px;
                    }
                }
                .el-image {
                    max-width: 100%;
                    height: 180px;
                    vertical-align: top;
                }
            }
            .left-img {
                padding-right: 5px;
            }
            .right-img {
                padding-left: 5px;
            }
        }
        .demo-ruleForm {
            text-align: left;
            overflow: hidden;
            position: relative;
            .sh-pic {
                position: absolute;
                top: 0;
                right: 0;
                z-index: 1;
            }
            .el-form-item {
                margin-bottom: 0 !important;
            }
            .el-row {
                margin: 0 !important;
                .el-col {
                    padding: 0 !important;
                    &.hide-form-item {
                        display: none;
                    }
                }
            }
        }
        .shButton {
            margin-top: 10px;
            margin-bottom: 10px;
            display: block;
            overflow: hidden;
        }
        .no-data {
            line-height: 30px;
            height: 30px;
            text-align: center;
        }
    }
}
</style>
