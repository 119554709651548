var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"common_detail",attrs:{"id":"common_detail"}},[_c('div',{staticClass:"title",attrs:{"title":_vm.taskMsg.title + '(' + _vm.taskTime + ')'}},[_vm._v(" "+_vm._s(_vm.taskMsg.title)+" ("+_vm._s(_vm.taskTime)+") ")]),(_vm.taskType === '1103')?_c('div',{staticClass:"recordListYkl scrollbar"},[_c('el-form',{ref:"ruleForm",staticClass:"demo-ruleForm daily-patrol-form"},[(_vm.taskRwzt === '1')?_c('img',{staticClass:"sh-pic",attrs:{"src":require(("@image/monitoring_cloud/sh_" + (_vm.infoDataAll[0].SHZT) + ".png")),"alt":""}}):_vm._e(),(_vm.infoDataAll && _vm.infoDataAll.length !== 0)?_c('el-row',{attrs:{"gutter":24}},[_c('el-col',{attrs:{"span":24}},[_c('el-form-item',{attrs:{"label":"上报人员"}},[_vm._v(" "+_vm._s(_vm.infoDataAll[0].CJRMC)+" ")])],1)],1):_vm._e(),(_vm.infoDataAll && _vm.infoDataAll.length !== 0)?_c('el-row',{attrs:{"gutter":24}},[_c('el-col',{attrs:{"span":24}},[_c('el-form-item',{attrs:{"label":"上报时间"}},[_vm._v(" "+_vm._s(_vm.infoDataAll[0].CJSJ)+" ")])],1)],1):_vm._e(),_vm._l((_vm.infoDataAll),function(ele,i){return _c('div',{key:i,staticClass:"form-item"},[_c('el-row',{attrs:{"gutter":24}},_vm._l((_vm.formList),function(item,i){return _c('el-col',{key:i,class:_vm.setYklShow(_vm.formList, item),attrs:{"span":24}},[_c('el-form-item',{attrs:{"label":item.label,"prop":item.value}},[_vm._v(" "+_vm._s(item.value ? ele[item.value] : "")+" "+_vm._s(item.unit || "")+" ")])],1)}),1)],1)})],2),(
                _vm.taskRwzt === '1' &&
                +_vm.infoDataAll[0].SHZT !== 2 &&
                +_vm.infoDataAll[0].SHZT !== 3
            )?_c('sn-button',{staticClass:"shButton",attrs:{"snButton":_vm.snButtonSH},on:{"update:snButton":function($event){_vm.snButtonSH=$event},"update:sn-button":function($event){_vm.snButtonSH=$event},"handleChange":_vm.handleChange}}):_vm._e()],1):_c('div',{staticClass:"recordList scrollbar"},[(_vm.infoData.JZZ)?_c('div',{staticClass:"img-box",on:{"click":function($event){return _vm.openContrastImg()}}},[_c('div',{staticClass:"left-img"},[_c('el-image',{attrs:{"fit":"cover","src":_vm.infoData.JZZ}},[_c('div',{staticClass:"image-slot",attrs:{"slot":"error"},slot:"error"},[_vm._v("暂无数据")])]),_c('p',[_vm._v("基准图")])],1),(_vm.showXcz)?_c('div',{staticClass:"right-img",on:{"click":function($event){return _vm.openContrastImg()}}},[_c('el-image',{attrs:{"fit":"cover","src":_vm.infoData.XXZ ? _vm.infoData.XXZ[0] : ''}},[_c('div',{staticClass:"image-slot",attrs:{"slot":"error"},slot:"error"},[_vm._v("暂无数据")])]),_vm._m(0)],1):_vm._e()]):_c('div',[(_vm.showXcz)?_c('div',[(_vm.infoData.PIC)?_c('el-form',{ref:"ruleForm1",staticClass:"demo-ruleForm daily-patrol-form",nativeOn:{"submit":function($event){$event.preventDefault();}}},[_c('el-row',{attrs:{"gutter":24}},[_c('el-col',{attrs:{"span":24}},[_c('el-form-item',{attrs:{"label":"照片","label-width":"45px"}},[_c('picVedio',{attrs:{"picCheck":false,"picVideoList":_vm.infoData.PIC,"srcName":'CCLJ'}})],1)],1)],1)],1):_c('p',{staticClass:"no-data"},[_vm._v("暂无数据")])],1):_vm._e()]),(_vm.taskRwzt === 1)?_c('el-form',{ref:"ruleForm",staticClass:"demo-ruleForm daily-patrol-form"},[_c('img',{staticClass:"sh-pic",attrs:{"src":require(("@image/monitoring_cloud/sh_" + (_vm.infoData.SHZT) + ".png")),"alt":""}}),_c('el-row',{attrs:{"gutter":24}},_vm._l((_vm.formList),function(item,i){return _c('el-col',{key:i,class:_vm.setShow(_vm.formList, item),attrs:{"span":24}},[_c('el-form-item',{attrs:{"label":item.label,"prop":item.value}},[_vm._v(" "+_vm._s(item.value ? _vm.infoData[item.value] : "")+" "),(
                                item.audioValue &&
                                _vm.infoData[item.audioValue] &&
                                _vm.infoData[item.audioValue].length > 0
                            )?_c('audios',{attrs:{"audioList":_vm.infoData[item.audioValue],"srcName":"CCLJ"}}):_vm._e()],1)],1)}),1)],1):_vm._e(),_vm._l((_vm.videlList),function(item,ind){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.taskType === '24'),expression:"taskType === '24'"}],key:ind},[_c('video',{attrs:{"width":"100%","controls":"controls"}},[_c('source',{attrs:{"src":item.CCLJ,"type":"video/mp4"}}),_c('source',{attrs:{"src":item.CCLJ,"type":"video/ogg"}}),_c('source',{attrs:{"src":item.CCLJ,"type":"video/webm"}}),_c('object',{attrs:{"src":item.CCLJ,"width":"100%"}},[_c('embed',{attrs:{"src":item.CCLJ,"width":"100%"}})])])])}),(!_vm.showXcz && _vm.infoData.PIC)?_c('div',[_c('el-form',{ref:"ruleForm1",staticClass:"demo-ruleForm daily-patrol-form",nativeOn:{"submit":function($event){$event.preventDefault();}}},[_c('el-row',{attrs:{"gutter":24}},[_c('el-col',{attrs:{"span":24}},[_c('el-form-item',{attrs:{"label":"照片","label-width":"45px"}},[_c('picVedio',{attrs:{"picCheck":false,"picVideoList":_vm.infoData.PIC,"srcName":'CCLJ'}})],1)],1)],1)],1)],1):_vm._e(),(
                _vm.taskRwzt === 1 &&
                +_vm.infoData.SHZT !== 2 &&
                +_vm.infoData.SHZT !== 3
            )?_c('sn-button',{staticClass:"shButton",attrs:{"snButton":_vm.snButtonSH},on:{"update:snButton":function($event){_vm.snButtonSH=$event},"update:sn-button":function($event){_vm.snButtonSH=$event},"handleChange":_vm.handleChange}}):_vm._e()],2),_c('sn-dialog',{staticClass:"custom-dialog",attrs:{"dialogData":_vm.dialogContrastImg},on:{"closeDialog":_vm.closeContrastImg}},[_c('div',{staticClass:"flex-between contrastImg-part"},[_c('div',{staticClass:"img-item"},[_c('el-image',{directives:[{name:"viewer",rawName:"v-viewer"}],staticClass:"pointer",staticStyle:{"width":"100%","height":"100%"},attrs:{"src":_vm.infoData.JZZ,"fit":"cover"}},[_c('div',{staticStyle:{"width":"100%","height":"100%"},attrs:{"slot":"error"},slot:"error"},[_c('SnNoData',{attrs:{"width":"200px","typeNum":"1"}})],1)]),_c('div',{staticClass:"label"},[_vm._v("基准图")])],1),_c('div',{staticClass:"img-item"},[_c('el-image',{directives:[{name:"viewer",rawName:"v-viewer"}],staticClass:"pointer",staticStyle:{"width":"100%","height":"100%"},attrs:{"src":_vm.infoData.XXZ ? _vm.infoData.XXZ[0] : '',"fit":"cover"}},[_c('div',{staticStyle:{"width":"100%","height":"100%"},attrs:{"slot":"error"},slot:"error"},[_c('SnNoData',{attrs:{"width":"200px","typeNum":"1"}})],1)]),_c('div',{staticClass:"label"},[_vm._v("现场照")])],1)])])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',[_c('span',[_vm._v("现场照")])])}]

export { render, staticRenderFns }