<template>
    <div id="common_detail" class="residential-construct-detail">
        <div class="title" :title="taskMsg.title + '(' + taskTime + ')'">
            {{ taskMsg.title }} ({{ taskTime }})
        </div>
        <!-- 有多张现场照，可为图片、视频 -->
        <div class="recordList scrollbar">
            <!-- 任务状态为已完成时显示采集字段 -->
            <el-form
                v-if="taskRwzt === 1"
                ref="ruleForm"
                class="demo-ruleForm daily-patrol-form"
            >
                <img
                    class="sh-pic"
                    :src="
                        require(`@image/monitoring_cloud/sh_${Number(
                            monitorData.SHZT
                        )}.png`)
                    "
                    alt=""
                />
                <div class="title-part">监测数据</div>
                <div class="flex flex-wrap">
                    <div
                        v-for="(item, i) in monitorList"
                        :key="i"
                        style="width: 50%"
                    >
                        <el-form-item :label="item.label" :prop="item.value">
                            {{ item.value ? monitorData[item.value] : "" }}
                        </el-form-item>
                    </div>
                </div>
                <div class="title-part" style="margin-top: 20px">基础数据</div>
                <div class="flex flex-wrap">
                    <div
                        v-for="(item, i) in basicList"
                        :key="i"
                        style="width: 50%"
                    >
                        <el-form-item
                            v-if="item.value == 'WQYS'"
                            prop=""
                            label="外墙颜色"
                            style="width: 100%; height: 30px"
                        >
                            <div
                                style="
                                    width: 30px;
                                    height: 30px;
                                    position: relative;
                                    left: 80px;
                                    top: 6px;
                                    border-radius: 4px;
                                "
                                :style="{
                                    background: basicData[item.value],
                                }"
                            ></div>
                        </el-form-item>
                        <el-form-item
                            v-else
                            :label="item.label"
                            :prop="item.value"
                        >
                            {{ item.value ? basicData[item.value] : "" }}
                        </el-form-item>
                    </div>
                </div>

                <el-form-item label="照片" label-width="45px">
                    <picVedio
                        :picCheck="false"
                        :picVideoList="monitorData.PIC"
                        srcName="CCLJ"
                    ></picVedio>
                </el-form-item>
            </el-form>
            <sn-button
                v-if="
                    taskRwzt === 1 &&
                    +monitorData.SHZT !== 2 &&
                    +monitorData.SHZT !== 3
                "
                class="shButton"
                :snButton.sync="snButtonSH"
                @handleChange="handleChange"
            ></sn-button>
        </div>
    </div>
</template>
<script>
import { mapActions } from "vuex";
import picVedio from "../task_manage/pic_video_show";
export default {
    name: "",
    components: {
        picVedio,
    },
    props: {
        detailData: {
            default: function () {
                return [];
            },
        },
        taskMsg: {
            default: function () {
                return {};
            },
        },
    },
    data() {
        return {
            taskTime: "",
            taskRwzt: null,
            taskType: "",
            jcpzid: "",
            rwid: "",

            snButtonSH: {
                buttonData: [
                    {
                        btnType: "button",
                        operateType: "pass",
                        name: "审核通过",
                        round: true,
                        backColor: "#28cdd9",
                        color: "#fff",
                    },
                    {
                        btnType: "button",
                        operateType: "noPass",
                        name: "审核不通过",
                        round: true,
                        backColor: "#6f32ff",
                        color: "#fff",
                    },
                ],
            },
            // 基础数据列表
            basicList: [
                {
                    label: "民居建设编号：",
                    value: "XJXMBH",
                },
                {
                    label: "村：",
                    value: "YCCZ",
                },
                {
                    label: "村民小组(组)：",
                    value: "YCYS",
                },
                {
                    label: "监测日期：",
                    value: "CJSJ",
                },

                {
                    label: "户主姓名：",
                    value: "HZXM",
                },
                {
                    label: "家庭人口：",
                    value: "JTRK",
                },
                {
                    label: "身份证号码：",
                    value: "SFZHM",
                },
                {
                    label: "联系电话：",
                    value: "MOBILE",
                },

                {
                    label: "宅基地情况：",
                    value: "ZJDQK_DESC",
                },
                {
                    label: "宅基地面积（m²）：",
                    value: "ZJDMJ",
                },

                {
                    label: "建设方案是否审批通过：",
                    value: "SFSPTG_DESC",
                },
                {
                    label: "房屋结构：",
                    value: "FWJG",
                },
                {
                    label: "房屋用途：",
                    value: "FWYT",
                },

                {
                    label: "申请占地面积（m²）：",
                    value: "SQZDMJ",
                },
                {
                    label: "实际占地面积（m²）：",
                    value: "SJZDMJ",
                },
                {
                    label: "申请建筑面积（m²）：",
                    value: "SQJZMJ",
                },
                {
                    label: "实际建筑面积（m²）：",
                    value: "SJJZMJ",
                },

                {
                    label: "房屋层数：",
                    value: "FWCS",
                },
                {
                    label: "房屋高度(m)：",
                    value: "FWGD",
                },
                {
                    label: "外墙颜色：",
                    value: "WQYS",
                },
                {
                    label: "屋顶形式：",
                    value: "WDXS",
                },

                {
                    label: "建房时间：",
                    value: "JFRQ",
                },
                {
                    label: "计划完成时间",
                    value: "JHJGRQ",
                },
                // {
                //     label: "竣工时间：",
                //     value: "JGSJ",
                // },
                {
                    label: "施工单位：",
                    value: "SGDW",
                },
                {
                    label: "施工负责人：",
                    value: "SGFZR",
                },
                {
                    label: "施工单位联系电话：",
                    value: "SGDWDH",
                },

                {
                    label: "备注：",
                    value: "XMSM",
                },
                {
                    label: "建设地点：",
                    value: "JSDD",
                },
                {
                    label: "其他建设地点：",
                    value: "QTJSDD",
                },
            ],
            basicData: {},
            // 监测数据列表
            monitorList: [
                {
                    label: "框架：",
                    value: "KJ_DESC",
                },
                {
                    label: "封顶：",
                    value: "FD_DESC",
                },
                {
                    label: "砌墙：",
                    value: "QQ_DESC",
                },

                {
                    label: "室内装修：",
                    value: "SNZX_DESC",
                },
                {
                    label: "室外装修：",
                    value: "SWZX_DESC",
                },
                {
                    label: "竣工：",
                    value: "JG_DESC",
                },

                {
                    label: "现状描述：",
                    value: "XZMS",
                },
                {
                    label: "存在问题：",
                    value: "CZWT",
                },
                {
                    label: "是否按已审批方案实施：",
                    value: "SFAYSPFASS_DESC",
                },
            ],
            // 监测数据对象
            monitorData: {},
        };
    },
    watch: {
        detailData: {
            handler() {
                this.taskType = this.taskMsg.type || "";
                this.basicData = this.detailData.basicdt[0];
                this.monitorData = { ...this.detailData.dt[0] };
                let pics = this.detailData.PIC;
                this.monitorData.FJ_SJMS = this.detailData.FJ_SJMS;
                this.monitorData.PIC = pics;
            },
            deep: true,
        },
        taskMsg: {
            handler() {
                this.taskTime = this.taskMsg.time
                    ? this.taskMsg.time.slice(5, 10)
                    : "";
                this.taskType = this.taskMsg.type || "";
                this.taskRwzt = this.taskMsg.rwzt;
                this.jcpzid = this.taskMsg.jcpzid;
                this.rwid = this.taskMsg.rwid;
            },
            deep: true,
        },
    },
    computed: {},
    methods: {
        ...mapActions(["updateSHZT"]),
        handleChange(type, value) {
            if (type === "pass" || type === "noPass") {
                let shzt = type === "pass" ? 2 : 3;
                this.$confirm("是否确认审核?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                })
                    .then(() => {
                        this.checkData(shzt);
                    })
                    .catch(() => {
                        this.common.showMsg("已取消审核", "info");
                    });
            }
        },
        async checkData(shzt) {
            let res = await this.updateSHZT({
                jcpzid: this.jcpzid,
                rwid: this.rwid,
                shzt: shzt,
            });
            if (res.IsSuccess) {
                this.monitorData.SHZT = shzt;
                this.$emit("changeShzt", shzt);
                this.common.showMsg("审核成功", "success");
            } else {
                this.common.showMsg("审核失败", "error");
            }
        },
    },
};
</script>
<style lang="scss" scoped>
.residential-construct-detail {
    height: 100%;
    .title {
        height: 35px;
        line-height: 35px;
        background-color: #336799;
        text-align: left;
        color: #fff;
        padding-left: 10px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
    }
    .recordList {
        background-color: #fff;
        height: calc(100% - 35px);
        padding: 10px;
        .demo-ruleForm {
            text-align: left;
            overflow: hidden;
            position: relative;
            .title-part {
                font-size: 16px;
                color: #606266;
                font-weight: 700;
                border-bottom: 1px solid #eee;
                padding-bottom: 10px;
                &::before {
                    content: "|";
                    width: 1px;
                    height: 10px;
                    background: #336799;
                    margin-right: 10px;
                }
            }
            .sh-pic {
                position: absolute;
                top: 0;
                right: 0;
                z-index: 1;
            }
            .el-form-item {
                margin-bottom: 0 !important;
            }
            .el-row {
                margin: 0 !important;
                .el-col {
                    padding: 0 !important;
                    &.hide-form-item {
                        display: none;
                    }
                }
            }
        }
        .shButton {
            margin-top: 10px;
            margin-bottom: 10px;
            display: block;
            overflow: hidden;
        }
    }
}
</style>
